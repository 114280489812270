import { ApolloLink, createHttpLink, from } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { get, isObject } from 'lodash';
import { messageContext } from './app/components/AppContextHolder';
import { APPLICATION_ID, ROUTES, TEAM_KEY, TOKEN } from './common/constants';
import history from './historyData';

let disableToastTimeout = null;
export const cacheData = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL,
});

const toast = ({ message: content, type }) => {
  messageContext?.destroy();
  switch (type) {
    case 'info':
      messageContext?.info(content);
      break;
    case 'success':
      messageContext?.success(content);
      break;
    case 'warning':
      messageContext?.warning(content);
      break;
    case 'error':
      messageContext?.error(content);
      break;
    default:
      break;
  }
};

const authLink = setContext((ctx, { headers }) => {
  // eslint-disable-next-line no-undef
  const userToken = headers?.token || localStorage?.getItem(TOKEN);
  // eslint-disable-next-line no-undef
  const teamKey = localStorage?.getItem(TEAM_KEY);
  // eslint-disable-next-line no-undef
  const applicationId = localStorage?.getItem(APPLICATION_ID);
  let newHeaders = headers || {};

  newHeaders = {
    ...newHeaders,
    // eslint-disable-next-line no-undef
    'User-Agent': navigator?.userAgent,
    Authorization: userToken ? `Bearer ${userToken}` : '',
    'x-workspace-key': teamKey,
    'x-application-key': applicationId,
  };

  return {
    headers: newHeaders,
  };
});

const responseMessageLink = new ApolloLink((operation, forward) =>
  forward(operation)?.map((response) => {
    const { data } = response;

    if (
      data &&
      isObject(data) &&
      Object?.keys(data)?.length > 0 &&
      data[`${Object?.keys(data)[0]}`] &&
      data[`${Object?.keys(data)[0]}`]?.message
    ) {
      if (Object?.keys(data)[0] === 'forgotUserPassword') {
        if (data[`${Object?.keys(data)[0]}`].status !== 'ERROR') {
          setTimeout(() => {
            toast({
              message:
                data[`${Object?.keys(data)[0]}`]?.message ||
                'Operation successful',
              type: 'success',
            });
          }, 1000);
        }
      } else {
        setTimeout(() => {
          const oResponse = data[`${Object?.keys(data)[0]}`];

          if (!response) {
            return;
          }

          toast({
            message: oResponse?.message || 'Operation successful',
            type: oResponse?.status === 'ERROR' ? 'error' : 'success',
          });
        }, 1000);
      }
    }
    return response;
  }),
);

const errorLink = onError((options) => {
  const { graphQLErrors, networkError, response } = options;

  if (networkError?.statusCode === 405) {
    if (disableToastTimeout) {
      clearTimeout(disableToastTimeout);
    }

    disableToastTimeout = setTimeout(() => {
      if (networkError?.result?.message) {
        toast({
          message: networkError?.result?.message,
          type: 'error',
        });
      }
    }, 200);

    history?.replace(ROUTES?.LOGOUT);
    return;
  }

  if (graphQLErrors?.length > 0) {
    const isForBidden =
      get(graphQLErrors[0], 'extensions.code') === 'FORBIDDEN';

    if (
      !isForBidden &&
      graphQLErrors?.[0]?.extensions?.code !== 'SESSION_EXPIRED' &&
      graphQLErrors?.[0]?.extensions?.code !== 'UNAUTHORIZED' &&
      graphQLErrors?.[0]?.extensions?.code !==
        'SUBSCRIPTION_CANCELLATION_RESTRICTED'
    ) {
      setTimeout(() => {
        toast({
          message: graphQLErrors?.[0]?.message,
          type: 'error',
        });
      }, 1000);
    }
  } else {
    setTimeout(() => {
      toast({
        message: 'Something went wrong!',
        type: 'error',
      });
    }, 1000);
    if (process.env.REACT_APP_GRAPHQL_SENTRY_ERROR_CHECK === 'true') {
      // Enable when sentry integrated
      Sentry?.captureException(new Error(`[Graphql error]: ${graphQLErrors}`));
    }
  }

  if (response) {
    response?.errors?.map((error) => {
      const { message: errorMessage, locations, path, extensions } = error;

      // Enable when sentry integrated
      Sentry?.captureException(
        new Error(
          `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`,
        ),
      );

      if (extensions?.code === 'FORBIDDEN') {
        history?.replace(ROUTES?.ACCESS_TOKEN);
      }

      if (extensions?.code === 'UNAUTHORIZED') {
        history?.replace(ROUTES?.UNAUTHORIZED);
      }

      if (
        extensions &&
        (extensions?.code === 'UNAUTHENTICATED' ||
          extensions?.code === 'SESSION_EXPIRED' ||
          extensions?.code === 405 ||
          extensions?.exception?.name === 'JsonWebTokenError')
      ) {
        if (extensions?.code === 'SESSION_EXPIRED') {
          history?.replace({
            pathname: ROUTES?.REFRESH_TOKEN,
            state: { from: history?.location?.pathname },
          });
        } else {
          history?.replace(ROUTES?.LOGOUT);
        }
      }

      // eslint-disable-next-line no-console
      return console.log(
        `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`,
      );
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
    Sentry?.captureException(new Error(`[Network error]: ${networkError}`));
  }
});

const client = new ApolloClient({
  cache: cacheData,
  link: from([responseMessageLink, errorLink, authLink, httpLink]),
});

export default client;
