import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
} from 'antd';
import axios from 'axios';
import { includes, trim } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { messageContext } from '../../app/components/AppContextHolder';
import editIcon from '../../assets/images/edit-icon.png';
import {
  ALL_IMAGE_UPLOAD,
  DELETE_ACCOUNT_REASONS,
  GA_EVENT,
  GA_LABEL,
  REGEX,
  ROUTES,
} from '../../common/constants';
import {
  formValidatorRules,
  googleAnalyticsEvents,
  previewImage,
} from '../../common/utils';
import { GET_SIGNED_URL } from '../onboard/graphql/mutation';
import { DELETE_ACCOUNT, UPDATE_USER } from './graphql/mutations';
import { GET_USER_TEAM } from './graphql/queries';
import './profile-settings.less';

const { required } = formValidatorRules;
const { TextArea } = Input;

const ProfileSettings = () => {
  const {
    state: { currentUser, teamPlan },
    dispatch,
  } = useContext(AppContext);

  const [form] = Form?.useForm();
  const history = useHistory();

  const [currentFile, setCurrentFile] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isOtherReason, setIsOtherReason] = useState(false);
  const [otherReason, setOtherReason] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const { data: workspaceData, loading: workspaceLoading } = useQuery(
    GET_USER_TEAM,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [deleteAccount] = useMutation(DELETE_ACCOUNT, {
    onCompleted() {
      setBtnLoading(false);
      dispatch({ type: 'LOGOUT' });
      history?.push(ROUTES?.LOGIN);
    },
    onError() {
      setBtnLoading(false);
    },
  });

  const [initialValue] = useState({
    firstName: currentUser?.firstName ? trim(currentUser?.firstName) : '',
    lastName: currentUser?.lastName ? trim(currentUser?.lastName) : '',
    email: currentUser?.email,
    defaultWorkspace: currentUser?.defaultWorkspace,
  });

  useEffect(() => {
    if (currentUser?.profileImage) {
      setImageUpload([{ url: currentUser?.profileImage }]);
    } else {
      setImageUpload([]);
    }
  }, [currentUser]);

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted() {
      setBtnLoading(false);
    },
    onError() {
      setBtnLoading(false);
    },
  });

  const [state, setState] = useState({
    selectedImage: {},
    uploading: false,
  });

  const [getSignedURL] = useMutation(GET_SIGNED_URL, {
    onError() {
      setBtnLoading(false);
      setState({ ...state, uploading: false, selectedImage: {} });
    },
  });

  const { selectedImage } = state;

  const handleUpload = async (value) => {
    const { firstName, lastName, defaultWorkspace } = value;
    setBtnLoading(true);
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData?.append('file', selectedImage);
    setState({
      ...state,
      uploading: true,
    });

    if (currentFile) {
      const res = await getSignedURL({
        variables: {
          data: {
            fileName: currentFile?.name,
            fileType: currentFile?.type,
          },
        },
      });
      const config = {
        headers: {
          'Content-Type': currentFile?.type,
          'x-amz-acl': 'public-read',
        },
      };

      try {
        await axios?.put(
          res?.data?.getSignedUrl?.signedURL,
          currentFile,
          config,
        );
        const data = {
          firstName,
          lastName,
          profileImage: res?.data?.getSignedUrl?.fileUrl,
          defaultWorkspace,
        };
        const userData = await updateUser({
          variables: {
            data,
          },
        });
        if (userData?.data?.updateUser) {
          dispatch({
            type: 'SET_CURRENT_USER',
            data: userData?.data?.updateUser,
          });
          setBtnLoading(false);
        }
        googleAnalyticsEvents(GA_EVENT?.USER_PROFILE_EDIT, {
          label: GA_LABEL?.USER_PROFILE_EDIT,
          first_name: firstName,
          last_name: lastName,
          profile_image: res?.data?.getSignedUrl?.fileUrl,
          current_plan_name: teamPlan?.subscriptionPlan?.name,
          current_plan_type: teamPlan?.subscriptionPlan?.type,
        });
      } catch (err) {
        return err;
      }
    } else {
      const userData = await updateUser({
        variables: {
          data: {
            firstName,
            lastName,
            profileImage: imageUpload?.[0]?.url || null,
            defaultWorkspace,
          },
        },
      });
      googleAnalyticsEvents(GA_EVENT?.USER_PROFILE_EDIT, {
        label: GA_LABEL?.USER_PROFILE_EDIT,
        first_name: firstName,
        last_name: lastName,
        profile_image: imageUpload?.[0]?.url || null,
        current_plan_name: teamPlan?.subscriptionPlan?.name,
        current_plan_type: teamPlan?.subscriptionPlan?.type,
      });
      if (userData?.data?.updateUser) {
        dispatch({
          type: 'SET_CURRENT_USER',
          data: userData?.data?.updateUser,
        });
        setBtnLoading(false);
      }
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleConfirmDelete = () => {
    googleAnalyticsEvents(GA_EVENT?.DELETE_ACCOUNT, {
      label: GA_LABEL?.DELETE_ACCOUNT,
      member_id: currentUser?.id,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });
    if (checkedList?.length > 0) {
      const payload = [...checkedList, ...otherReason].filter(
        (item) => item !== 'OTHER',
      );
      deleteAccount({
        variables: {
          data: {
            reason: payload,
          },
        },
      });
      setCheckedList([]);
      setOtherReason([]);
      setIsConfirmed(false);
      setModalVisible(false);
    }
  };

  const onChange = (list) => {
    setCheckedList(list);
  };

  useEffect(() => {
    setIsOtherReason(includes(checkedList, 'OTHER'));
  }, [checkedList]);

  const onChangeImageUpload = async (info) => {
    const {
      file: { name = '', url },
    } = info;

    const ext = name?.substring(name?.lastIndexOf('.') + 1);

    if (ALL_IMAGE_UPLOAD?.includes(ext) && !url) {
      setImageUpload([...info?.fileList]);
      setCurrentFile(info?.file);
    } else if (info?.file?.status === 'removed') {
      setCurrentFile(null);
      setImageUpload([]);
    } else {
      messageContext?.destroy();
      messageContext?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  return (
    <>
      <div className="profile-card">
        <div className="card-content">
          <Card
            title="Profile Settings"
            bordered
            className="card-title"
            loading={workspaceLoading}
          >
            <Form
              name="profile-settings"
              layout="vertical"
              form={form}
              onFinish={handleUpload}
              initialValues={initialValue}
            >
              <Form.Item name="profileImage">
                <div className="profile-upload">
                  <Upload
                    maxCount={1}
                    onChange={onChangeImageUpload}
                    previewFile={previewImage}
                    fileList={imageUpload}
                    beforeUpload={() => false}
                    listType="picture-circle"
                    showUploadList={{ showPreviewIcon: false }}
                  >
                    {!imageUpload?.length && (
                      <img src={editIcon} alt="icon" className="icon-image" />
                    )}
                  </Upload>
                  <span className="profile-heading font-12">
                    Update Profile Image
                  </span>
                </div>
              </Form.Item>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        ...required,
                        whitespace: true,
                        message: 'Enter a valid First Name',
                      },
                      {
                        pattern: REGEX?.NAME,
                        message: 'Enter a valid First Name',
                      },
                    ]}
                  >
                    <Input placeholder="Enter First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        ...required,
                        whitespace: true,
                        message: 'Enter a valid Last Name',
                      },
                      {
                        pattern: REGEX?.NAME,
                        message: 'Enter a valid Last Name',
                      },
                    ]}
                  >
                    <Input placeholder="Enter Last Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="email" label="Email Id">
                <Input disabled />
              </Form.Item>
              <Form.Item name="defaultWorkspace" label="Default WorkSpace">
                <Select
                  placeholder="Select Default WorkSpace"
                  name="defaultWorkspace"
                  loading={workspaceLoading}
                >
                  {workspaceData?.getUserTeams?.map((team) => (
                    <Select.Option value={team?.id} key={team?.id}>
                      {team?.teamName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Divider className="card-divider" />
              <Row className="button-row">
                <Button
                  danger
                  onClick={() => {
                    setModalVisible(true);
                  }}
                  icon={<DeleteOutlined />}
                >
                  Delete Account
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={btnLoading}
                  className="save-btn"
                >
                  Save
                </Button>
              </Row>
            </Form>
          </Card>
        </div>
      </div>
      <Modal
        title={
          <div className="line-26">
            {!isConfirmed
              ? 'Are you sure you want to delete your account?'
              : 'Why do you want to delete your account?'}
          </div>
        }
        width={736}
        open={modalVisible}
        onCancel={handleCloseModal}
        closable={false}
        wrapClassName="url-prefix-modal"
        footer={[
          <div key="action">
            <Button
              className="previous mr-16"
              onClick={() => {
                setCheckedList([]);
                setModalVisible(false);
                setIsConfirmed(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="next"
              danger={isConfirmed}
              type="primary"
              disabled={isConfirmed && checkedList?.length <= 0}
              onClick={() =>
                !isConfirmed ? setIsConfirmed(true) : handleConfirmDelete()
              }
            >
              {isConfirmed ? 'Yes, Delete Account' : 'Confirm'}
            </Button>
          </div>,
        ]}
        centered
      >
        <div className="d-flex flex-vertical p-16 gap-16">
          {!isConfirmed ? (
            <div className="d-flex flex-vertical align-center font-16">
              Deleting your account will permanently erase all of your data and
              cancel any active subscriptions. This action is irreversible, and
              no refunds will be issued.
            </div>
          ) : (
            <div className="d-flex flex-vertical gap-16">
              <Checkbox.Group
                className="d-flex gap-16 flex-vertical"
                options={DELETE_ACCOUNT_REASONS}
                value={checkedList}
                onChange={onChange}
              />
              {isOtherReason && (
                <TextArea
                  onChange={(e) => setOtherReason([e?.target?.value])}
                  rows={4}
                />
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ProfileSettings;
