import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useEffect } from 'react';

const PurchaseConfirmationModal = ({ visible, setVisible }) => {
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (visible) {
      modal.confirm({
        title: 'Confirmation',
        icon: <ExclamationCircleOutlined />,
        content:
          'Are you sure want to purchase this item and it automatically deduct amount from your saved payment!',
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: () => {
          setVisible(false);
        },
        onCancel: () => {
          setVisible(false);
        },
      });
    }
  }, [visible, modal, setVisible]);

  return contextHolder;
};

export default PurchaseConfirmationModal;
