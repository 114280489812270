import { gql } from '@apollo/client';

export const SUBSCRIPTION_PLANS = gql`
  query subscriptionPlans {
    subscriptionPlans {
      count
      data {
        id
        name
        slug
        mode
        type
        amount
        description
        currency
        priceId
        isActive
        recurring
        subscriptionFeatures
        features
        createdAt
        updatedAt
        accountUsage
        team {
          id
          teamName
          teamLogo
          teamConfig {
            id
            features
            config
          }
        }
      }
    }
  }
`;

export const CURRENT_PLAN_FOR_USER = gql`
  query TeamSubscriptionPlan($data: TeamUniqueInput!) {
    teamSubscriptionPlan(data: $data) {
      data {
        request {
          ... on TeamSubscriptions {
            id
            subscriptionPlan {
              id
              name
              slug
              mode
              type
              amount
              description
              currency
              priceId
              isActive
              recurring
              subscriptionFeatures
              features
              createdAt
              updatedAt
              accountUsage
            }
            team {
              id
              teamName
              teamLogo
              teamConfig {
                id
                features
                config
              }
            }
            subscriptionStatus
            isAddons
            subscriptionAmount
            quantity
            subscriptionRenewalDate
            subscriptionCancellationDate
            metaData
            createdAt
            updatedAt
            accountUsage
            subscriberId
            paddleInvoiceUrl
          }
          ... on SubscriptionPlan {
            id
            name
            slug
            mode
            type
            amount
            description
            currency
            priceId
            isActive
            recurring
            subscriptionFeatures
            features
            createdAt
            updatedAt
            accountUsage
            team {
              id
              teamName
              teamLogo
              teamConfig {
                id
                features
                config
              }
            }
          }
        }
      }
    }
  }
`;

export const FAQS_LISTING = gql`
  query faqs {
    faqs {
      count
      data {
        id
        question
        answer
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
