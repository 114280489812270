import { Card, Col, Row } from 'antd';
import React from 'react';
import { currencyFormat, formatPurchaseDate } from '../../../../common/utils';

function AddonDetails({ teamPlanAddOns = null }) {
  return (
    <>
      <h1 className="page-title">Add-ons details</h1>
      <div className="current-plan-details">
        <Row gutter={24}>
          <Col span={8}>
            <Card
              title={
                <div className="d-flex justify-between align-center">
                  Current Billing
                </div>
              }
            >
              <div className="blue-text">
                {currencyFormat.format(teamPlanAddOns?.currentBilling || 0)}
              </div>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title={
                <div className="d-flex justify-between">Next Payment Due</div>
              }
            >
              <div className="blue-text">
                {teamPlanAddOns?.data?.length > 0
                  ? formatPurchaseDate(teamPlanAddOns?.nextPaymentDue)
                  : '-'}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddonDetails;
