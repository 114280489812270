import { gql } from '@apollo/client';

export const PURCHASE_SUBSCRIPTION = gql`
  mutation purchaseSubscription($data: SubscriptionPlanInput!) {
    purchaseSubscription(data: $data) {
      data {
        planId
        teamSubscriptionId
        transactionId
      }
    }
  }
`;

export const SYNC_SUBSCRIPTION = gql`
  mutation syncSubscriptionPlans {
    syncSubscriptionPlans {
      message
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($data: TeamSubscriptionUniqueInput!) {
    cancelSubscription(data: $data) {
      message
    }
  }
`;

export const UPGRADE_DOWNGRADE_SUBSCRIPTION = gql`
  mutation UpgradeDowngradeSubscriptionPlan(
    $where: SubscriptionPlanUniqueInput!
    $data: upgradeDowngradeSubscriptionPlanInput
  ) {
    upgradeDowngradeSubscriptionPlan(where: $where, data: $data) {
      message
      amount
      url
    }
  }
`;
