import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { ACTIONS, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import WorkspaceHeader from './WorkSpaceHeader';
import BillingHistoryList from './billing-history/BillingHistoryList';
import ManageAddOns from './manage-addons/ManageAddOns';
import PlanAndPayment from './plans-and-payment/PlanAndPayment';
import WorkspaceSettings from './settings/WorkspaceSettings';

const WorkspaceWrapper = () => (
  <>
    <WorkspaceHeader />
    <Switch>
      <Route
        exact
        path={`${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.WORKSPACE_SETTINGS}/:teamId`}
        component={WorkspaceSettings}
      />
      <Route
        exact
        path={`${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/:teamId`}
        render={(props) => (
          <AccessControl
            allowedPermissions={ACTIONS?.PLANS_AND_PAYMENTS}
            showNoAccess
          >
            <PlanAndPayment {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.MANAGE_ADD_ONS}/:teamId`}
        render={(props) => (
          <AccessControl
            allowedPermissions={ACTIONS?.PLANS_AND_PAYMENTS}
            showNoAccess
          >
            <ManageAddOns {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.BILLING_HISTORY}/:teamId`}
        render={(props) => (
          <AccessControl
            allowedPermissions={ACTIONS?.PLANS_AND_PAYMENTS}
            showNoAccess
          >
            <BillingHistoryList {...props} />
          </AccessControl>
        )}
      />
      <Route path="*" component={Error404} />
    </Switch>
  </>
);

export default WorkspaceWrapper;
