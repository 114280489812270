import * as Sentry from '@sentry/react';
import { Card } from 'antd';
import { isMobile } from 'mobile-device-detect';
import React, { useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { Route, Router, Switch } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { AppContext } from './AppContext';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import { ErrorIcon } from './assets/svg';
import { ROUTES } from './common/constants';
import MaintenancePage from './components/MaintenancePage';
import SmartBanner from './components/SmartBanner';
import history from './historyData';
import IosDeviceUdid from './modules/app-details/IosDeviceUdid';
import PublicPage from './modules/app-details/PublicPage';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import NewUser from './modules/auth/NewUser';
import RefreshToken from './modules/auth/RefreshToken';
import VerifyUser from './modules/auth/VerifyUser';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA?.initialize(TRACKING_ID);

const MyFallbackComponent = ({ error, componentStack }) => (
  <div className="d-flex flex-vertical align-center">
    <div className="d-flex flex-vertical align-center">
      <ErrorIcon className="mt-10" width="65px" height="90px" />
      <p className="font-xx-large">
        <strong>Oops! An error occurred!</strong>
      </p>
      <Card className="width-percent-60">
        <p className="d-flex font-18">
          <strong className="font-large mr-5">Error: </strong>
          <p className="line-24"> {error?.message?.toString()}</p>
        </p>
        <p>
          <strong className="font-large">Stacktrace:</strong>
          <p className="line-24">{componentStack}</p>
        </p>
      </Card>
    </div>
  </div>
);

const Routes = () => {
  const {
    dispatch,
    state: { smartBanner },
  } = useContext(AppContext);
  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;
  // eslint-disable-next-line no-undef
  const { pathname } = window?.location;
  const isLoginPage = pathname === ROUTES?.LOGIN;

  useEffect(() => {
    const cookies = new Cookies();
    const loggedInCheckCookie = cookies?.get('isLoggedIn');
    if (!loggedInCheckCookie) {
      // eslint-disable-next-line no-undef
      localStorage?.clear();
      cookies?.set('isLoggedIn', true, { path: '/' });
      dispatch({
        type: 'SET_LOADING',
        data: false,
      });
      // eslint-disable-next-line no-undef
      window?.location?.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA?.send({
      hitType: 'pageview',
      page: pathname,
    });
  }, [pathname]);

  // useEffect(() => {
  // if (process.env.REACT_APP_ENV === 'production') {
  // #AOA-1712 Commented as data is not added yet discussed in meeting
  // Gleap.initialize(process.env.REACT_APP_GLEAP_KEY);
  // }
  // }, []);

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      {isMobile && smartBanner && <SmartBanner />}
      <Helmet>
        <title>{isLoginPage ? 'Login | AppsOnAir' : 'AppsOnAir'}</title>
        {isLoginPage && (
          <meta
            name="description"
            content="Instantly share APK and IPA files, manage testers, and get real-time notifications for efficient app testing and feedback"
          />
        )}
        {isLoginPage && (
          <link rel="canonical" href="https://app.appsonair.com/login" />
        )}
      </Helmet>
      <Router history={history}>
        {maintenance === 'true' ? (
          <MaintenancePage />
        ) : (
          <Switch>
            <PublicRoute exact path={ROUTES?.LOGIN} component={Login} />
            <PublicRoute
              exact
              path={ROUTES?.VERIFY_USER}
              component={VerifyUser}
            />
            <Route
              exact
              path={`${ROUTES?.INSTALL}/:subLinkId`}
              component={PublicPage}
            />
            <Route exact path={ROUTES?.IOS_UDID} component={IosDeviceUdid} />
            <PrivateRoute
              exact
              path={ROUTES?.CREATE_USER}
              component={NewUser}
            />
            <PrivateRoute exact path={ROUTES?.LOGOUT} component={Logout} />
            <Route
              exact
              path={ROUTES?.REFRESH_TOKEN}
              component={RefreshToken}
            />
            <PrivateRoute path={ROUTES?.MAIN} component={App} />
          </Switch>
        )}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default Routes;
