import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import Error404 from '../../Error404';
import { ACTIONS, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import { GET_TEAM } from '../teams/graphql/queries';
import ApiKey from './Apikey';
import AppDetails from './AppDetails';
import AppList from './AppList';
import AppSettings from './AppSettings';
import AppShare from './AppShare';
import MaintenanceServices from './MaintenanceServices';
import UpdateServices from './UpdateServices';
import UploadRelease from './UploadRelease';
import Feedbacks from './feedbacks/Feedbacks';

const AppWrapper = () => {
  const {
    state: { teamId },
  } = useContext(AppContext);
  const [teamData, setTeamData] = useState();

  const [getTeam] = useLazyQuery(GET_TEAM, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setTeamData(res?.getTeam?.teamDetails);
    },
  });

  useEffect(() => {
    if (teamId) {
      getTeam({
        variables: { teamId },
      });
    }
  }, [teamId]);

  return (
    <Switch>
      <Route exact path={ROUTES?.APPS} component={AppList} />
      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/:appId`}
        component={AppDetails}
      />
      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/:appId${ROUTES?.NEW_RELEASE}`}
        render={(props) => (
          <AccessControl allowedPermissions={ACTIONS?.APP_RELEASE} showNoAccess>
            <UploadRelease {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.RELEASE}/:appId`}
        render={(props) => (
          <AccessControl allowedPermissions={ACTIONS?.APP_RELEASE} showNoAccess>
            <UploadRelease {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/:appId${ROUTES?.CREATE_RELEASE}`}
        render={(props) => (
          <AccessControl allowedPermissions={ACTIONS?.APP_RELEASE} showNoAccess>
            <UploadRelease {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.SHARE}/:appId`}
        component={AppShare}
      />
      {/* comment for prod AOA-1156
    <Route
      exact
      path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.DYNAMIC_LINK}/:appId`}
      component={DynamicLink}
    />
    <Route
      exact
      path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.DYNAMIC_LINK}/:appId${ROUTES?.CREATE_NEW_DYNAMIC_LINK}`}
      component={CreateNewDynamicLink}
    />
    <Route
      exact
      path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.DYNAMIC_LINK}/:appId${ROUTES?.EDIT_DYNAMIC_LINK}`}
      component={CreateNewDynamicLink}
    /> */}
      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}${ROUTES?.UPDATE}/:appId`}
        render={(props) => (
          <AccessControl
            allowedPermissions={ACTIONS?.UPDATE_APP_SERVICE}
            showNoAccess
          >
            <UpdateServices {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}${ROUTES?.MAINTENANCE}/:appId`}
        render={(props) => (
          <AccessControl
            allowedPermissions={ACTIONS?.UPDATE_APP_SERVICE}
            showNoAccess
          >
            <MaintenanceServices {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}${ROUTES?.API_KEY}/:appId`}
        render={(props) => (
          <AccessControl
            allowedPermissions={ACTIONS?.UPDATE_APP_SERVICE}
            showNoAccess
          >
            <ApiKey {...props} />
          </AccessControl>
        )}
      />
      {teamData?.allowFeedbacks && (
        <Route
          exact
          path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.FEEDBACKS}/:appId`}
          component={Feedbacks}
        />
      )}
      <Route
        exact
        path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APP_SETTINGS}/:appId`}
        component={AppSettings}
      />
      <Route path="*" component={Error404} />
    </Switch>
  );
};

export default AppWrapper;
