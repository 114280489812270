import { AndroidFilled, AppleFilled, EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Modal, Tag } from 'antd';
import React, { useState } from 'react';
import { ACTIONS } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import HTMLEditor from '../../../components/HTMLEditor';
import { UPDATE_RELEASE_NOTE } from '../graphql/mutations';
import '../style/app-details.less';

function ViewReleaseNoteModal({
  releaseRecord,
  ReleaseNotes,
  showReleaseModal,
  handleVisible,
  selectedPlatform,
  data,
  version,
  build,
  refetchReleaseDetails,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [updateReleaseNoteData, setUpdateReleaseNoteData] = useState(
    ReleaseNotes,
  );

  const [updateReleaseNote] = useMutation(UPDATE_RELEASE_NOTE, {
    onCompleted() {
      setIsEdit(false);
      handleVisible(false);
      setSaveLoading(false);
      refetchReleaseDetails();
    },
    onError() {
      setSaveLoading(false);
    },
  });

  const handleCancel = () => {
    handleVisible(false);
    setIsEdit(false);
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleUpdateReleaseNote = () => {
    setSaveLoading(true);
    updateReleaseNote({
      variables: {
        data: {
          id: releaseRecord?.id,
          isSpecificNote: releaseRecord?.isSpecificNote,
          ...(!releaseRecord?.isSpecificNote && {
            oneReleaseNote: updateReleaseNoteData,
          }),
          ...(releaseRecord?.isSpecificNote &&
            releaseRecord?.appPlatform === 'ANDROID' && {
              androidReleaseNote: updateReleaseNoteData,
            }),
          ...(releaseRecord?.isSpecificNote &&
            releaseRecord?.appPlatform === 'IOS' && {
              iosReleaseNote: updateReleaseNoteData,
            }),
        },
      },
    });
  };

  return (
    <div>
      <Modal
        title={
          <>
            <span>{data?.getApplication?.appName}</span>
            {selectedPlatform === 'IOS' ? (
              <AppleFilled size={18} className="ios-color" />
            ) : (
              <AndroidFilled className="android-color" />
            )}
            <Tag>
              <span>Version: {version}</span>
            </Tag>
            <Tag>
              <span>Build: {build}</span>
            </Tag>
          </>
        }
        className="release-note"
        width={578}
        footer={
          AccessControl({
            allowedPermissions: ACTIONS?.APP_RELEASE,
          })
            ? [
                <div key="footer">
                  {!isEdit ? (
                    <Button
                      onClick={handleEdit}
                      htmlType="submit"
                      className="upload-btn"
                      type="primary"
                    >
                      Edit <EditOutlined />
                    </Button>
                  ) : (
                    <Button
                      onClick={handleUpdateReleaseNote}
                      htmlType="submit"
                      className="upload-btn"
                      type="primary"
                      loading={saveLoading}
                    >
                      Save
                    </Button>
                  )}
                </div>,
              ]
            : null
        }
        onCancel={handleCancel}
        open={showReleaseModal}
        centered
      >
        <div className="heading">Release Note </div>
        <div>
          {isEdit ? (
            <HTMLEditor
              className="release-html"
              value={updateReleaseNoteData}
              onChange={setUpdateReleaseNoteData}
            />
          ) : (
            <>
              {ReleaseNotes ? (
                <div
                  className="release-html"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: ReleaseNotes }}
                />
              ) : (
                <span>No Data Available</span>
              )}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default ViewReleaseNoteModal;
