import { Col, Descriptions, Divider, Drawer, Image, Row } from 'antd';
import { capitalize } from 'lodash';
import React from 'react';
import { DEVICE_INFO_FIELDS } from '../../../../common/constants';

const FeedbackDetails = ({ open, onClose, feedbackDetails }) => {
  const mapDeviceInfoToArray = (info) =>
    Object?.entries(DEVICE_INFO_FIELDS)?.map(([key, label]) => ({
      key,
      label,
      children: info?.[key] || '-',
    }));
  return (
    <>
      <Drawer width={940} placement="right" onClose={onClose} open={open}>
        {feedbackDetails?.attachments?.length > 0 && (
          <>
            <p className="font-600 font-16 line-24">Attachments</p>
            <Row>
              <div className="d-flex gap-24">
                <Image.PreviewGroup>
                  {feedbackDetails?.attachments?.map((img) => (
                    <div key={img?.key}>
                      <Col span={24}>
                        <Image
                          className="custom-img"
                          height={200}
                          src={img?.key}
                          alt="Feedback Image"
                        />
                      </Col>
                    </div>
                  ))}
                </Image.PreviewGroup>
              </div>
            </Row>
            <Divider />
          </>
        )}
        <Row>
          <Descriptions
            title="Basic Info"
            items={[
              {
                key: 'type',
                label: 'Type',
                children: `${capitalize(feedbackDetails?.type)}`,
              },
              {
                key: 'description',
                label: 'Description',
                children: `${feedbackDetails?.description}`,
              },
              {
                key: 'appsOnAirSDKVer',
                label: 'AppsOnAir SDK Version',
                children: `${feedbackDetails?.deviceInfo?.appsOnAirSDKVersion}`,
              },
            ]}
          />
        </Row>
        <Divider />
        <Row>
          <Descriptions
            title="Device Info"
            items={mapDeviceInfoToArray(feedbackDetails?.deviceInfo)}
          />
        </Row>
      </Drawer>
    </>
  );
};

export default FeedbackDetails;
