import {
  AndroidFilled,
  AppleFilled,
  DeleteOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
  LockOutlined,
  PlusOutlined,
  ShareAltOutlined,
  WechatOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Carousel,
  Checkbox,
  Dropdown,
  Row,
  Select,
  Tooltip,
} from 'antd';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { messageContext } from '../../app/components/AppContextHolder';
import ReleaseNotes from '../../assets/images/release-notes.png';
import { EditIcon, PlanCoverLink, UpgradeCrown } from '../../assets/svg';
import documentCopy from '../../assets/svg/document-copy.svg';
import { ACTIONS, GA_EVENT, GA_LABEL, ROUTES } from '../../common/constants';
import { copyToClipboard, googleAnalyticsEvents } from '../../common/utils';
import AccessControl from '../../components/AccessControl';
import AppNameHeader from '../../components/AppNameHeader';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import LatestRelease from './LatestRelease';
import NoReleases from './NoRelease';
import AppServiceProFeatureModal from './components/AppServiceProFeatureModal';
import BuildShareModal from './components/BuildShareModal';
import DeleteReleaseModal from './components/DeleteReleaseModal';
import ReleaseFeedbackModal from './components/ReleaseFeedbackModal';
import ViewAllShareLinks from './components/ViewAllShareLinks';
import ViewPermissionModal from './components/ViewPermissionModal';
import ViewProvisionalModal from './components/ViewProvisionalModal';
import ViewReleaseNoteModal from './components/ViewReleaseNoteModal';
import {
  DELETE_RELEASE,
  DELETE_SHARABLE_LINK,
  UPDATE_DOWNLOAD_COUNT,
} from './graphql/mutations';
import {
  GET_APP,
  GET_APP_RELEASE_DETAILS,
  GET_SHARABLE_LINKS,
} from './graphql/queries';
import './style/app-details.less';

function getQuery() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { search = '' } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => new URLSearchParams(search), [search]);
}
const AppDetails = () => {
  const appId = useParams();
  const id = appId?.appId;
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [proFeatureModalOpen, setProFeatureModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [platform, setAppPlatform] = useState('All');
  const [appIsResolve, setAppIsResolve] = useState(false);
  const [appBuild, setAppBuild] = useState();
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [appVersion, setAppVersion] = useState();
  const [viewReleaseRecord, setViewReleaseRecord] = useState();
  const [permissions, setPermissions] = useState('');
  const [releaseNotes, setReleaseNotes] = useState('');
  const [releaseModal, setReleaseModal] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [provisionalModal, setProvisionalModal] = useState(false);
  const [viewShareLinksModal, setViewShareLinksModal] = useState(false);
  const [releaseFeedbackModal, setReleaseFeedbackModal] = useState(false);
  const [deleteAppModal, setDeleteAppModal] = useState(false);
  const [releaseCreatedBy, setReleaseCreatedBy] = useState();
  const [buildShareModal, setBuildShareModal] = useState(false);
  const [rec, setRec] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refetchType, setRefetchType] = useState(null);
  const query = getQuery();
  const [editBuildShareModal, setEditBuildShareModal] = useState(false);

  const history = useHistory();
  const {
    dispatch,
    state: { currentUser, releaseDefaultLink, teamId, teamPlan },
  } = useContext(AppContext);

  useEffect(() => {
    if (appId?.appId) {
      dispatch({
        type: 'SET_APPLICATION_ID',
        data: appId?.appId,
      });
    }
    if (query?.get('teamId')) {
      dispatch({
        type: 'SET_TEAM',
        data: query?.get('teamId'),
      });
      history?.replace(history?.location?.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1,
  });

  const shareRelease = (buildDetail) => {
    setBuildShareModal(true);
    setRec(buildDetail);
  };

  const editShareRelease = (editBuildDetail) => {
    setBuildShareModal(true);
    setEditBuildShareModal(true);
    setViewShareLinksModal(false);
    setResponse(editBuildDetail);
    setRec(viewReleaseRecord);
    setRec(viewReleaseRecord);
  };

  const applicationDetails = {
    id,
    skip,
    limit,
    platform,
    includeArchived: appIsResolve,
  };

  const getSharableData = {
    uniqueId: selectedRow,
    skip,
    limit,
  };
  const [
    getSharableLinks,
    { data: shareLinksData, loading: ShareLinksLoading },
  ] = useLazyQuery(GET_SHARABLE_LINKS, {
    fetchPolicy: 'network-only',
    variables: {
      data: getSharableData,
    },
    onError(error) {
      return error;
    },
  });

  const {
    data: getApplicationDetails,
    loading: getApplicationDetailsLoading,
    refetch,
  } = useQuery(GET_APP_RELEASE_DETAILS, {
    variables: {
      data: applicationDetails,
    },
    fetchPolicy: 'network-only',
    onError() {
      setLoading(false);
    },
    onCompleted: (res) => {
      if (releaseDefaultLink) {
        setSelectedRow(
          res?.getApplicationsReleaseDetails?.applicationData
            ?.applicationDetails?.[0]?.applicationUniqueId,
        );
        getSharableLinks({
          variables: {
            data: {
              ...getSharableData,
              uniqueId:
                res?.getApplicationsReleaseDetails?.applicationData
                  ?.applicationDetails?.[0]?.applicationUniqueId,
            },
          },
        });
        setViewReleaseRecord(
          res?.getApplicationsReleaseDetails?.applicationData
            ?.applicationDetails?.[0],
        );
        setSelectedPlatform(
          res?.getApplicationsReleaseDetails?.applicationData
            ?.applicationDetails?.[0]?.appPlatform,
        );
        setAppVersion(
          res?.getApplicationsReleaseDetails?.applicationData
            ?.applicationDetails?.[0]?.appVersion,
        );
        setAppBuild(
          res?.getApplicationsReleaseDetails?.applicationData
            ?.applicationDetails?.[0]?.appBuild,
        );
        setSelectedPlatform(
          res?.getApplicationsReleaseDetails?.applicationData
            ?.applicationDetails?.[0]?.appPlatform,
        );
        setViewShareLinksModal(true);
        dispatch({
          type: 'SET_APP_RELEASE_DEFAULT_LINK',
          data: false,
        });
      }
      const pagination = {
        ...paginationProp,
        total:
          platform === 'All'
            ? res?.getApplicationsReleaseDetails?.totalReleasesCount
            : res?.length,
      };
      setLoading(false);
      setPaginationProp(pagination);
    },
  });

  const newRelease = () => {
    googleAnalyticsEvents(GA_EVENT?.NEW_RELEASE, {
      label: GA_LABEL?.NEW_RELEASE,
      member_id: currentUser?.id,
      application_id:
        getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
          ?.id,
      application_name:
        getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
          ?.appName,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });
    const path = `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/${id}${ROUTES?.NEW_RELEASE}`;
    history?.push(path);
  };

  const [updateDownloadCount] = useMutation(UPDATE_DOWNLOAD_COUNT, {
    onCompleted: () => {
      if (refetch) {
        refetch();
      }
    },
    onError(error) {
      return error;
    },
  });

  const [deleteSharableLink] = useMutation(DELETE_SHARABLE_LINK, {
    onError(error) {
      return error;
    },
  });

  const [deleteRelease] = useMutation(DELETE_RELEASE, {
    variables: {
      uniqueId: selectedRow,
    },
    onCompleted: () => {
      if (refetch) {
        refetch();
        setRefetchType(selectedPlatform);
      }
      dispatch({
        type: 'SET_REFETCH_TEAM_PLAN_USAGE',
        data: true,
      });
    },
    onError(error) {
      return error;
    },
    refetchQueries: [
      {
        query: GET_APP_RELEASE_DETAILS,
        variables: {
          data: applicationDetails,
        },
      },
    ],
  });

  const { data: getAppDetails, loading: getAppDetailsLoading } = useQuery(
    GET_APP,
    {
      fetchPolicy: 'network-only',
      variables: {
        appId: id,
      },
      onError(error) {
        return error;
      },
    },
  );

  const deleteSharable = (linkId) => {
    deleteSharableLink({
      variables: {
        uniqueId: selectedRow,
        subLinkId: linkId,
      },
      refetchQueries: [
        {
          query: GET_SHARABLE_LINKS,
          variables: {
            data: getSharableData,
          },
        },
      ],
    });
  };

  const copyLink = (link) => {
    copyToClipboard(link);
    messageContext?.success('Link Copied to Clipboard');
  };

  const modalColumns = [
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      className: 'link',
      width: '50%',
      render: (recordId, record) => {
        const isPrivate = record?.isPrivate ? <LockOutlined /> : '';
        const link = record?.link;

        const copyLinkToClipboard = (
          <img
            src={documentCopy}
            alt="copy-icon"
            onClick={() => copyLink(link)}
            className="pointer"
          />
        );

        return (
          <div className="link-details d-flex justify-center align-center flex-horizontal">
            {isPrivate}
            <div className="link-text">{link}</div>
            {copyLinkToClipboard}
          </div>
        );
      },
    },
    {
      title: 'Expiry',
      dataIndex: 'remainingDays',
      key: 'remainingDays',
      className: 'expiry',
      width: '20%',
      render: (recordId, record) => {
        const remainingDays = record?.remainingDays;

        const start = moment();
        const end = moment()?.add(remainingDays, 'days');

        const months = end?.diff(start, 'months');
        start?.add(months, 'months');

        const days = end?.diff(start, 'days');

        const tooltip = (
          <div className="tooltip">
            {record?.remainingDays <= 7 && record?.remainingDays > 0 && (
              <Tooltip
                title={`Share link expires in ${record?.remainingDays} days`}
              >
                <InfoCircleOutlined className="ml-8" />
              </Tooltip>
            )}
          </div>
        );

        return (
          <div className="d-flex flex-start align-center expiry-details">
            <span>
              {months >= 1 && days >= 0 ? (
                <>
                  {days === 0 && <span>{months} months</span>}
                  {days !== 0 && (
                    <span>
                      {months} months {days} days
                    </span>
                  )}
                </>
              ) : (
                <span>{days} days</span>
              )}
            </span>
            <span className="tooltip">{tooltip}</span>
          </div>
        );
      },
    },
    ...(AccessControl({ allowedPermissions: ACTIONS?.APP_SHARE })
      ? [
          {
            title: ' ',
            dataIndex: 'action',
            key: 'action',
            className: 'action',
            width: '10%',
            render: (recordId, record) => (
              <div className="action-btn d-flex justify-around align-center">
                <EditIcon
                  className="pointer"
                  onClick={() => editShareRelease(record)}
                />
                <DeleteOutlined
                  className="danger"
                  onClick={() => deleteSharable(record?.subLinkId)}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  const handlePagination = (pagination) => {
    const { current } = pagination;
    const skipNumber = (current - 1) * pagination?.pageSize;

    if (!_isEmpty(pagination)) {
      setSkip(skipNumber);
      setLimit(pagination?.pageSize);
      setPaginationProp({ ...paginationProp, ...pagination });
    }
    setPaginationProp({ ...paginationProp, ...pagination });
    setSkip(skipNumber);
  };

  const handleFilterChange = (key) => {
    if (key === 'ANDROID') {
      setSkip(0);
      setAppPlatform('ANDROID');
    } else if (key === 'IOS') {
      setSkip(0);
      setAppPlatform('IOS');
    } else {
      setSkip(0);
      setAppPlatform('All');
    }
  };

  const handleFilterIsResolve = ({ target: { checked } }) => {
    setAppIsResolve(checked);
    setSkip(0);
  };

  const updateDownloadCountFunc = (record) => {
    try {
      googleAnalyticsEvents(GA_EVENT?.RELEASE_DOWNLOAD, {
        label: GA_LABEL?.RELEASE_DOWNLOAD,
        member_id: currentUser?.id,
        application_id:
          getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
            ?.id,
        application_name:
          getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
            ?.appName,
        current_plan_name: teamPlan?.subscriptionPlan?.name,
        current_plan_type: teamPlan?.subscriptionPlan?.type,
      });
      setTimeout(() => {
        updateDownloadCount({
          variables: { applicationDetailId: record?.id },
        });
        if (record?.appPlatform === 'ANDROID') {
          setRefetchType('ANDROID');
        } else {
          setRefetchType('IOS');
        }
      }, 500);
    } catch (error) {
      return error;
    }
  };

  const items = [
    {
      key: 'release',
      label: (
        <div className="menu-releases d-flex align-center">
          <img alt="release-notes" src={ReleaseNotes} />
          &nbsp; View release note
        </div>
      ),
      onClick: () => {
        googleAnalyticsEvents(GA_EVENT?.RELEASE_VIEW_RELEASE_NOTE, {
          label: GA_LABEL?.RELEASE_VIEW_RELEASE_NOTE,
          member_id: currentUser?.id,
          application_id:
            getApplicationDetails?.getApplicationsReleaseDetails
              ?.applicationData?.id,
          application_name:
            getApplicationDetails?.getApplicationsReleaseDetails
              ?.applicationData?.appName,
          current_plan_name: teamPlan?.subscriptionPlan?.name,
          current_plan_type: teamPlan?.subscriptionPlan?.type,
        });
        setReleaseModal(true);
      },
    },
    {
      key: 'divider-1',
      type: 'divider',
      className: 'ellipsis-menu-divider',
    },
    selectedPlatform === 'ANDROID'
      ? {
          key: 'permissions',
          label: 'Permissions',
          onClick: () => {
            googleAnalyticsEvents(GA_EVENT?.RELEASE_PERMISSION, {
              label: GA_LABEL?.RELEASE_PERMISSION,
              member_id: currentUser?.id,
              application_id:
                getApplicationDetails?.getApplicationsReleaseDetails
                  ?.applicationData?.id,
              application_name:
                getApplicationDetails?.getApplicationsReleaseDetails
                  ?.applicationData?.appName,
              current_plan_name: teamPlan?.subscriptionPlan?.name,
              current_plan_type: teamPlan?.subscriptionPlan?.type,
            });
            setPermissionModal(true);
          },
        }
      : {
          key: 'provisional',
          label: 'Provisional devices',
          onClick: () => {
            setProvisionalModal(true);
          },
        },
    {
      key: 'shareLinks',
      label: 'View all share links',
      onClick: () => {
        googleAnalyticsEvents(GA_EVENT?.RELEASE_VIEW_ALL_SHARE_LINKS, {
          label: GA_LABEL?.RELEASE_VIEW_ALL_SHARE_LINKS,
          member_id: currentUser?.id,
          application_id:
            getApplicationDetails?.getApplicationsReleaseDetails
              ?.applicationData?.id,
          application_name:
            getApplicationDetails?.getApplicationsReleaseDetails
              ?.applicationData?.appName,
          current_plan_name: teamPlan?.subscriptionPlan?.name,
          current_plan_type: teamPlan?.subscriptionPlan?.type,
        });
        getSharableLinks();
        setViewShareLinksModal(true);
      },
    },
    ...(AccessControl({ allowedPermissions: ACTIONS?.DELETE_RELEASE }) ||
    currentUser?.id === releaseCreatedBy
      ? [
          {
            key: 'divider-2',
            type: 'divider',
            className: 'ellipsis-menu-divider',
          },
          {
            key: 'deleteApp',
            label: (
              <>
                <DeleteOutlined className="mr-8" /> <span>Delete release</span>
              </>
            ),
            onClick: () => {
              googleAnalyticsEvents(GA_EVENT?.RELEASE_DELETE, {
                label: GA_LABEL?.RELEASE_DELETE,
                member_id: currentUser?.id,
                application_id:
                  getApplicationDetails?.getApplicationsReleaseDetails
                    ?.applicationData?.id,
                application_name:
                  getApplicationDetails?.getApplicationsReleaseDetails
                    ?.applicationData?.appName,
                current_plan_name: teamPlan?.subscriptionPlan?.name,
                current_plan_type: teamPlan?.subscriptionPlan?.type,
              });
              setDeleteAppModal(true);
            },
            className: 'delete',
            danger: true,
          },
        ]
      : []),
  ];

  const handleDropdownClick = (record) => {
    setViewReleaseRecord(record);
    setSelectedPlatform(record?.appPlatform);
    setAppVersion(record?.appVersion);
    setAppBuild(record?.appBuild);
    setSelectedRow(record?.applicationUniqueId);
    setReleaseCreatedBy(record?.createdBy);
    if (record?.appPlatform === 'ANDROID')
      setPermissions(record?.appMetadata?.permissions);
    else setPermissions(record?.appMetadata?.provisionedDevices);
    if (!record?.isSpecificNote) {
      setReleaseNotes(record?.oneReleaseNote);
    } else if (record?.appPlatform === 'ANDROID') {
      setReleaseNotes(record?.androidReleaseNote);
    } else {
      setReleaseNotes(record?.iosReleaseNote);
    }
  };

  const columns = [
    {
      title: ' ',
      dataIndex: 'icon',
      key: 'appPlatform',
      className: 'app-platform',
      render: (recordId, record) => {
        const icon =
          record?.appPlatform === 'ANDROID' ? (
            <AndroidFilled className="android-color" />
          ) : (
            <AppleFilled className="ios-color" />
          );
        return icon;
      },
    },
    {
      title: 'Version',
      dataIndex: 'appVersion',
      key: 'appVersion',
      className: 'version',
      render: (recordId, record) => {
        const lock = record?.isPrivate && <LockOutlined />;
        const app = record?.appVersion;

        return (
          <div>
            <span>{app}</span>
            {lock && (
              <span>
                <Tooltip title="Private">{lock}</Tooltip>
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Build',
      dataIndex: 'appBuild',
      key: 'appBuild',
    },
    {
      title: 'Downloads',
      dataIndex: 'downloads',
      key: 'downloads',
    },
    {
      title: 'Uploaded on',
      key: 'createdAt',
      render: (record) => (
        <div>
          <span>{record?.createdAt?.split('T')?.[0]}</span>
        </div>
      ),
    },
    {
      title: ' ',
      dataIndex: 'id',
      key: 'id',
      className: 'action',

      render: (recordId, record) => (
        <div className="action-col">
          {record?.isArchived ? (
            <Tooltip title="Archived">
              <Button className="archived">
                <LockOutlined />
              </Button>
            </Tooltip>
          ) : (
            <>
              <div className="hover-btn">
                <Tooltip title="Download">
                  <Button
                    className="download-hover-btn"
                    href={record?.appStorageUrl}
                    onClick={() => updateDownloadCountFunc(record)}
                  >
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
                <AccessControl allowedPermissions={ACTIONS?.APP_SHARE}>
                  <Tooltip
                    title={record?.linkCheck === 1 ? 'Reshare' : 'Share'}
                  >
                    <Button
                      className="download-hover-btn"
                      onClick={() => {
                        googleAnalyticsEvents(GA_EVENT?.RELEASE_SHARE, {
                          label: GA_LABEL?.RELEASE_SHARE,
                          member_id: currentUser?.id,
                          application_id:
                            getApplicationDetails?.getApplicationsReleaseDetails
                              ?.applicationData?.id,
                          application_name:
                            getApplicationDetails?.getApplicationsReleaseDetails
                              ?.applicationData?.appName,
                          current_plan_name: teamPlan?.subscriptionPlan?.name,
                          current_plan_type: teamPlan?.subscriptionPlan?.type,
                        });
                        shareRelease(record);
                      }}
                    >
                      <ShareAltOutlined />
                    </Button>
                  </Tooltip>
                </AccessControl>
                {process.env.REACT_APP_APPS_FEEDBACKS_SHOW === 'true' && (
                  <Tooltip title="Feedbacks">
                    <Button
                      className="download-hover-btn"
                      onClick={() => {
                        googleAnalyticsEvents(GA_EVENT?.RELEASE_FEEDBACK, {
                          label: GA_LABEL?.RELEASE_FEEDBACK,
                          member_id: currentUser?.id,
                          application_id:
                            getApplicationDetails?.getApplicationsReleaseDetails
                              ?.applicationData?.id,
                          application_name:
                            getApplicationDetails?.getApplicationsReleaseDetails
                              ?.applicationData?.appName,
                          current_plan_name: teamPlan?.subscriptionPlan?.name,
                          current_plan_type: teamPlan?.subscriptionPlan?.type,
                        });
                        setViewReleaseRecord(record);
                        setReleaseFeedbackModal(true);
                      }}
                    >
                      <WechatOutlined />
                    </Button>
                  </Tooltip>
                )}
              </div>
              <div className="tooltip">
                <Tooltip
                  title={
                    record?.remainingDays < 1
                      ? 'Share link expired'
                      : `Share link expires in ${record?.remainingDays} days`
                  }
                >
                  {record?.remainingDays <= 7 &&
                    record?.remainingDays !== null && <InfoCircleOutlined />}
                </Tooltip>
              </div>
              <div>
                <Dropdown
                  overlayClassName="dropdown"
                  menu={{ items }}
                  placement="bottomRight"
                  trigger={['click']}
                  getPopupContainer={(triggerNode) => triggerNode}
                  dropdownRender={(menu) => (
                    <div className="ellipsis-menu">
                      {React.cloneElement(menu)}
                    </div>
                  )}
                >
                  <EllipsisOutlined
                    onClick={() => handleDropdownClick(record)}
                    rotate={90}
                  />
                </Dropdown>
              </div>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <AppNameHeader />
      {loading || getAppDetailsLoading ? (
        <LoaderComponent setHeight={200} />
      ) : (
        <>
          {getApplicationDetails?.getApplicationsReleaseDetails
            ?.totalReleasesCount === 0 &&
          !getApplicationDetailsLoading &&
          getApplicationDetails?.getApplicationsReleaseDetails
            ?.releasesArchivedCount === 0 &&
          platform === 'All' ? (
            <NoReleases />
          ) : (
            <>
              <LatestRelease
                refetchTableData={refetch}
                refetchType={refetchType}
                setRefetchType={setRefetchType}
              />
              <div className="apps-details-content">
                <div className="app-details-inner">
                  <Row className="table-row">
                    <div className="table-header">
                      <div className="table-header-left">
                        <h2>All Releases</h2>
                      </div>
                      <div className="table-header-right">
                        <Checkbox
                          className="mr-10"
                          onChange={handleFilterIsResolve}
                        >
                          Show Archived
                          <Tooltip title="Release will archive after 30 days if you not share or share link gets expired!">
                            <InfoCircleOutlined className="ml-4" />
                          </Tooltip>
                        </Checkbox>
                        <div className="filter-platform">
                          <span className="mr-8">Filter By Platform</span>
                          <div className="select-platform">
                            <Select
                              placeholder="All"
                              name="platform"
                              onChange={handleFilterChange}
                              getPopupContainer={(triggerNode) => triggerNode}
                              defaultValue="ALL"
                            >
                              <Select.Option value="ALL">All</Select.Option>
                              <Select.Option value="ANDROID">
                                Android
                              </Select.Option>
                              <Select.Option value="IOS">iOS</Select.Option>
                            </Select>
                          </div>
                        </div>
                        <AccessControl
                          allowedPermissions={ACTIONS?.APP_RELEASE}
                        >
                          <Button
                            type="primary"
                            className="new-release-btn"
                            onClick={newRelease}
                          >
                            <PlusOutlined />
                            New Release
                          </Button>
                        </AccessControl>
                      </div>
                    </div>
                    <div className="table">
                      <CommonTable
                        rowKey={(e) => e?.id}
                        className="table-content"
                        columns={columns}
                        loading={getApplicationDetailsLoading}
                        data={
                          getApplicationDetails?.getApplicationsReleaseDetails
                            ?.applicationData?.applicationDetails
                        }
                        onChange={handlePagination}
                        paginationConfig={paginationProp}
                      />
                    </div>
                  </Row>
                </div>
                {releaseModal && (
                  <ViewReleaseNoteModal
                    releaseRecord={viewReleaseRecord}
                    ReleaseNotes={releaseNotes}
                    selectedPlatform={selectedPlatform}
                    showReleaseModal={releaseModal}
                    handleVisible={setReleaseModal}
                    data={getAppDetails}
                    version={appVersion}
                    build={appBuild}
                    refetchReleaseDetails={refetch}
                  />
                )}
                {permissionModal && (
                  <ViewPermissionModal
                    PermissionNotes={permissions}
                    showPermissionModal={permissionModal}
                    handleVisible={setPermissionModal}
                    selectedPlatform={selectedPlatform}
                    data={getAppDetails}
                    version={appVersion}
                    build={appBuild}
                  />
                )}
                {provisionalModal && (
                  <ViewProvisionalModal
                    ProvisionalNotes={permissions}
                    showProvisionalModal={provisionalModal}
                    handleVisible={setProvisionalModal}
                    data={getAppDetails}
                    version={appVersion}
                    build={appBuild}
                  />
                )}
                {viewShareLinksModal && (
                  <ViewAllShareLinks
                    showShareLinkModal={viewShareLinksModal}
                    handleVisible={setViewShareLinksModal}
                    data={shareLinksData}
                    version={appVersion}
                    selectedPlatform={selectedPlatform}
                    columns={modalColumns}
                    loading={ShareLinksLoading}
                    refetchTableData={refetch}
                  />
                )}
                {releaseFeedbackModal && (
                  <ReleaseFeedbackModal
                    visible={releaseFeedbackModal}
                    handleVisible={setReleaseFeedbackModal}
                    releaseRecord={viewReleaseRecord}
                  />
                )}
                {deleteAppModal && (
                  <DeleteReleaseModal
                    showDeleteReleaseModal={deleteAppModal}
                    handleVisible={setDeleteAppModal}
                    selectedPlatform={selectedPlatform}
                    data={getAppDetails}
                    version={appVersion}
                    build={appBuild}
                    mutation={deleteRelease}
                    uniqueId={selectedRow}
                    applicationDetails={applicationDetails}
                  />
                )}
                {buildShareModal && (
                  <BuildShareModal
                    setBuildShareModal={setBuildShareModal}
                    buildShareModal={buildShareModal}
                    rec={rec}
                    getApplicationDetails={getApplicationDetails}
                    id={id}
                    setEditBuildShareModal={setEditBuildShareModal}
                    editBuildShareModal={editBuildShareModal}
                    response={response}
                    setProFeatureModalOpen={setProFeatureModalOpen}
                    getSharableLinks={getSharableLinks}
                    getSharableData={getSharableData}
                    setSelectedRow={setSelectedRow}
                    setResponse={setResponse}
                    refetchTableData={refetch}
                  />
                )}
                {proFeatureModalOpen && (
                  <AppServiceProFeatureModal
                    title="Build share"
                    visible={proFeatureModalOpen}
                    setVisible={setProFeatureModalOpen}
                    footer={
                      <div className="d-flex justify-around">
                        <Button
                          type="primary"
                          className="upgrade-button"
                          onClick={() => {
                            history?.push(
                              `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                            );
                          }}
                        >
                          <UpgradeCrown />
                          Upgrade to pro
                        </Button>
                      </div>
                    }
                    content={
                      <Carousel autoplay draggable>
                        <div className="app-service-carousel">
                          <PlanCoverLink />
                          <div className="text">
                            <p>
                              Upgrade to Pro for 60 and 90 days of Extended App
                              Share Links!
                            </p>
                          </div>
                        </div>
                      </Carousel>
                    }
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AppDetails;
