import { PlayCircleOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import VideoThumbnail from 'react-video-thumbnail';
import playPlaceholder from '../../../assets/images/play-placeholder.png';

const VideoThumbnailPreview = ({ videoUrl }) => {
  const [showPreview, setShowPreview] = useState(false);

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  return (
    <div>
      <div className="video-thumbnail" onClick={togglePreview}>
        <VideoThumbnail videoUrl={videoUrl} renderThumbnail />
        <img
          src={playPlaceholder}
          className="video-placeholder"
          alt="play"
          height="32px"
          width="32px"
        />
        <PlayCircleOutlined />
      </div>
      {showPreview && (
        <Modal
          className="video-player-modal"
          width={800}
          open={showPreview}
          onCancel={togglePreview}
          footer={false}
          destroyOnClose
        >
          <div className="d-flex justify-center p-24">
            <video className="h-full video-adjust" controls>
              <source src={videoUrl} />
              <track kind="captions" src={videoUrl} label="Feedback Video" />
            </video>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default VideoThumbnailPreview;
