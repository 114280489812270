import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import {
  ApplicationCountRestriction,
  StorageRestriction,
  TeamRestriction,
} from '../../../../assets/svg';
import '../plan-and-payment.less';

const CancelPlanRestrictionModal = ({ visible, handleVisible, freePlan }) => {
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      key: 'APPLICATION',
      title: 'Manage Applications to Stay Within Free Plan Limits',
      content: (
        <div>
          <div>
            <ApplicationCountRestriction />
          </div>
          <div className="text-area">
            <div className="number-icon">01</div>
            <div>
              <h3>Check Application Count:</h3>
              <p>
                Ensure the number of applications does not exceed the limit of
                the free plan. For example, if the free plan allows 1
                application and the user has 5 applications, they need to delete
                4 applications to meet the limit.
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'STORAGE',
      title: 'Optimize Storage by Managing App Releases',
      content: (
        <div>
          <div>
            <StorageRestriction />
          </div>
          <div className="text-area">
            <div className="number-icon">02</div>
            <div>
              <h3>Manage App Releases to Reduce Storage:</h3>
              <p>
                Review the app releases and delete unnecessary releases to free
                up storage space. As a free user you are only allowed upto{' '}
                {freePlan?.subscriptionFeatures?.STORAGE}.
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'TEAM_ROLES',
      title: 'Manage Team Roles to Comply with Free Plan Limits',
      content: (
        <div>
          <div>
            <TeamRestriction />
          </div>
          <div className="text-area">
            <div className="number-icon">03</div>
            <div>
              <h3>Check Team Members:</h3>
              <p>
                Verify the Team Members in the workspace with roles assigned as
                either ADMIN or DEVELOPER. If the number of such members exceeds
                the limit specified under the Free Plan (e.g., the Free Plan
                allows only one Team Member, but the workspace has five members
                with these roles), take the following steps:
              </p>
              <ul>
                <li>Remove the excess members from the workspace.</li>
                <li>
                  Alternatively, change their roles to TESTER to ensure
                  compliance with the Free Plan limits.
                </li>
              </ul>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const handleNext = () => {
    setCurrent(current + 1);
  };

  const handlePrevious = () => setCurrent(current - 1);

  return (
    <Modal
      width={713}
      centered
      title={<h2>{steps[current]?.title}</h2>}
      closeIcon={false}
      open={visible}
      footer={false}
      destroyOnClose
      maskClosable={false}
      className="cancel-subscription-restriction-modal"
    >
      <div className="steps-content">
        {steps[current]?.content}
        <div className="next-button">
          {current > 0 && (
            <Button
              className="previous"
              variant="outlined"
              onClick={handlePrevious}
            >
              Previous
            </Button>
          )}
          {current < steps.length - 1 ? (
            <Button className="next" variant="outlined" onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button
              variant="outlined"
              type="primary"
              onClick={() => handleVisible(false)}
            >
              I, Understood
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CancelPlanRestrictionModal;
