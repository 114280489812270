import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { REFRESH_TOKEN, ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { GET_REFRESH_TOKEN } from './graphql/queries';

const RefreshToken = () => {
  const history = useHistory();
  const {
    location: { state },
  } = history;

  const { initializeAuth } = useContext(AppContext);
  // eslint-disable-next-line no-undef
  const refreshToken = localStorage?.getItem(REFRESH_TOKEN);

  const { loading } = useQuery(GET_REFRESH_TOKEN, {
    variables: {
      refreshToken,
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      initializeAuth(res?.getRefreshToken?.token);
      if (state?.from) {
        history?.replace(state?.from);
      } else {
        history?.goBack();
      }
    },
    onError() {
      history?.replace(ROUTES?.LOGOUT);
    },
  });
  return <LoaderComponent spinning={loading} />;
};

export default RefreshToken;
