import { useLazyQuery } from '@apollo/client';
import { Button, Input, Row, Select } from 'antd';
import { capitalize, debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SearchIcon } from '../../../assets/svg';
import { LIMIT, SORT_BY, SORT_ON } from '../../../common/constants';
import AppNameHeader from '../../../components/AppNameHeader';
import CommonTable from '../../../components/CommonTable';
import FeedbackDetails from './components/FeedbackDetails';
import './feedbacks.less';
import { GET_FEEDBACK, GET_FEEDBACKS } from './graphql/queries';

let searchDebounce = null;

const initialFilter = {
  sort: {
    sortBy: SORT_BY.DESC,
    sortOn: SORT_ON.createdAt,
  },
  filter: {
    skip: 0,
    limit: LIMIT,
  },
  tableParams: {
    current: 1,
  },
};

export default function Feedbacks() {
  const { appId } = useParams();

  const [open, setOpen] = useState(false);
  const [paginationProp, setPaginationProp] = useState(initialFilter);

  const [getFeedbacks, { data, loading: loadingList }] = useLazyQuery(
    GET_FEEDBACKS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [getFeedback, { data: feedbackDetails }] = useLazyQuery(GET_FEEDBACK, {
    fetchPolicy: 'network-only',
    onCompleted() {
      setOpen(true);
    },
    onError() {},
  });

  useEffect(() => {
    getFeedbacks({
      variables: {
        ...paginationProp,
        filter: {
          ...paginationProp?.filter,
          appId,
        },
      },
    });
  }, [paginationProp]);

  const showFeedbackDrawer = ({ id }) => {
    getFeedback({
      variables: {
        where: {
          feedbackId: id,
        },
      },
    });
  };

  const onClose = () => {
    setOpen(false);
  };

  const handlePagination = (pagination) => {
    const { current } = pagination;
    const skipNumber = (current - 1) * pagination?.pageSize;
    setPaginationProp({
      ...paginationProp,
      filter: {
        ...paginationProp?.filter,
        skip: skipNumber,
      },
      tableParams: {
        ...paginationProp?.tableParams,
        current,
      },
    });
  };

  const handleFilterChange = async (value) => {
    const preparePayload = {
      ...paginationProp,
      filter: {
        ...paginationProp?.filter,
        skip: 0,
        limit: LIMIT,
        type: value,
        search: '',
      },
      tableParams: {
        ...paginationProp?.tableParams,
        current: 1,
      },
    };
    setPaginationProp(preparePayload);
  };

  const handleAppSearch = (value) => {
    getFeedbacks({
      variables: {
        ...paginationProp,
        filter: {
          ...paginationProp?.filter,
          appId,
          search: value,
        },
      },
    });
  };

  const handleChange = ({ target: { value } }) => {
    if (searchDebounce) {
      searchDebounce?.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(handleAppSearch, 500);
    searchDebounce(value);
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (value) => (value ? capitalize(value) : '-'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (value) => value ?? '-',
    },
    {
      title: 'App Version',
      key: 'appVersion',
      render: (_, record) => record?.deviceInfo?.releaseVersionNumber ?? '-',
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => {
        const dateObject = new Date(record?.createdAt);

        const day = dateObject?.getUTCDate();
        const month = dateObject?.getUTCMonth() + 1;
        const year = dateObject?.getUTCFullYear();

        const formattedDate = `${month < 10 ? '0' : ''}${month}-${
          day < 10 ? '0' : ''
        }${day}-${year}`;

        return formattedDate;
      },
    },
    {
      title: 'Action',
      key: 'action',
      className: 'action',
      width: '5%',
      render: (record) => (
        <Button
          type="link"
          onClick={() => showFeedbackDrawer({ id: record?.id })}
        >
          view more
        </Button>
      ),
    },
  ];

  return (
    <>
      <AppNameHeader />
      <div className="apps-details-content">
        <div className="app-details-inner">
          <Row className="table-row">
            <div className="table-header">
              <div className="table-header-left">
                <h2>Feedbacks</h2>
              </div>
              <div className="feedback-table-header-right">
                <div className="search-input">
                  <Input
                    size="small"
                    placeholder="Search"
                    allowClear
                    onChange={handleChange}
                    suffix={<SearchIcon />}
                  />
                </div>
                <div className="select-type">
                  <Select
                    placeholder="Select type"
                    allowClear
                    onChange={handleFilterChange}
                  >
                    <Select.Option value="SUGGESTION">Suggestion</Select.Option>
                    <Select.Option value="BUG">Bug</Select.Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="table">
              <CommonTable
                rowKey={(e) => e?.id}
                className="table-content"
                columns={columns}
                loading={loadingList}
                data={data?.feedbacks?.feedbacks}
                onChange={handlePagination}
                paginationConfig={{
                  total: data?.feedbacks?.count,
                  pageSize: paginationProp?.filter?.limit,
                  current: paginationProp?.tableParams?.current,
                }}
              />
            </div>
          </Row>
        </div>
      </div>
      {open && (
        <FeedbackDetails
          open={open}
          onClose={onClose}
          feedbackDetails={feedbackDetails?.feedback}
        />
      )}
    </>
  );
}
