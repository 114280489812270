import { gql } from '@apollo/client';

export const ADD_ONS = gql`
  query Team($filters: AddonsFilterInput, $sort: AddonsSortInput) {
    addons(filters: $filters, sort: $sort) {
      data {
        id
        name
        label
        slug
        mode
        type
        amount
        description
        currency
        priceId
        isActive
        recurring
        subscriptionFeatures
        features
        createdAt
        updatedAt
        accountUsage
        team {
          teamName
          teamLogo
          teamConfig {
            config
            features
            id
          }
          id
        }
      }
    }
  }
`;

export const MANAGE_ADD_ONS = gql`
  query TeamAddons($data: TeamUniqueInput!) {
    teamAddons(data: $data) {
      currentBilling
      nextPaymentDue
      data {
        id
        subscriptionPlan {
          id
          name
          slug
          label
          mode
          type
          amount
          description
          currency
          priceId
          isActive
          recurring
          subscriptionFeatures
          features
          createdAt
          updatedAt
          accountUsage
        }
        team {
          id
          teamName
          teamLogo
          teamConfig {
            id
            features
            config
          }
        }
        subscriptionStatus
        isAddons
        subscriptionAmount
        quantity
        subscriptionRenewalDate
        subscriptionCancellationDate
        metaData
        createdAt
        updatedAt
        accountUsage
        subscriberId
        paddleInvoiceUrl
      }
      count
    }
  }
`;
