import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoaderComponent from '../../components/LoaderComponent';
import { UPDATE_DOWNLOAD_COUNT } from './graphql/mutations';
import { GET_PLATFORM_SPECIFIC_RELEASE } from './graphql/queries';

const LatestRelease = ({ refetchTableData, refetchType, setRefetchType }) => {
  const appId = useParams();
  const id = appId?.appId;
  const [iosData, setIsoData] = useState();
  const [androidData, setAndroidData] = useState();

  const { loading: iosDataLoading, refetch: refetchIos } = useQuery(
    GET_PLATFORM_SPECIFIC_RELEASE,
    {
      fetchPolicy: 'network-only',
      variables: {
        id,
        platform: 'IOS',
      },
      onCompleted(response) {
        setIsoData(response);
      },
      onError(error) {
        return error;
      },
    },
  );

  const { loading: androidDataLoading, refetch: refetchAndroid } = useQuery(
    GET_PLATFORM_SPECIFIC_RELEASE,
    {
      fetchPolicy: 'network-only',
      variables: {
        id,
        platform: 'ANDROID',
      },
      onCompleted(response) {
        setAndroidData(response);
      },
      onError(error) {
        return error;
      },
    },
  );

  const [iosUpdateDownloadCount, { loading: iosCountLoading }] = useMutation(
    UPDATE_DOWNLOAD_COUNT,
    {
      variables: {
        applicationDetailId: iosData?.getPlatformSpecificLatestRelease?.id,
      },
      onCompleted: () => {
        if (refetchTableData) {
          refetchTableData();
        }
      },
      onError(error) {
        return error;
      },
      refetchQueries: [
        {
          query: GET_PLATFORM_SPECIFIC_RELEASE,
          variables: {
            id,
            platform: 'IOS',
          },
        },
      ],
    },
  );

  const [apkUpdateDownloadCount, { loading: apkCountLoading }] = useMutation(
    UPDATE_DOWNLOAD_COUNT,
    {
      variables: {
        applicationDetailId: androidData?.getPlatformSpecificLatestRelease?.id,
      },
      onCompleted: () => {
        if (refetchTableData) {
          refetchTableData();
        }
      },
      onError(error) {
        return error;
      },
      refetchQueries: [
        {
          query: GET_PLATFORM_SPECIFIC_RELEASE,
          variables: {
            id,
            platform: 'ANDROID',
          },
        },
      ],
    },
  );

  useEffect(() => {
    if (refetchType === 'ANDROID') {
      refetchAndroid();
      setRefetchType(null);
    } else if (refetchType === 'IOS') {
      refetchIos();
      setRefetchType(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchType]);

  const updateDownloadFunc = (type) => {
    setTimeout(() => {
      if (type === 'ANDROID') {
        apkUpdateDownloadCount();
      } else if (type === 'IOS') {
        iosUpdateDownloadCount();
      }
    }, 500);
  };

  if (iosDataLoading || androidDataLoading)
    return <LoaderComponent setHeight={700} />;

  return (
    <>
      <div className="apps-content">
        <div className="app-details-inner">
          <Row gutter={[12, 16]}>
            <Col md={24} lg={24} xl={10} xxl={10}>
              <Card
                size="small"
                title="Latest Release :"
                className="latest-release-card"
              >
                {androidData?.getPlatformSpecificLatestRelease?.appVersion && (
                  <Button
                    type="primary"
                    className="download-btn align-center mr-12"
                    loading={apkCountLoading}
                    href={
                      androidData?.getPlatformSpecificLatestRelease
                        ?.appStorageUrl
                    }
                    onClick={() => updateDownloadFunc('ANDROID')}
                  >
                    <span className="download-span">
                      Download&nbsp;
                      {androidData?.getPlatformSpecificLatestRelease
                        ?.appVersion || 'N/A'}
                    </span>
                    <AndroidFilled className="font-16" />
                  </Button>
                )}

                {iosData?.getPlatformSpecificLatestRelease?.appVersion && (
                  <Button
                    loading={iosCountLoading}
                    type="primary"
                    className="download-btn align-center"
                    href={
                      iosData?.getPlatformSpecificLatestRelease?.appStorageUrl
                    }
                    onClick={() => updateDownloadFunc('IOS')}
                  >
                    <span className="download-span">
                      Download&nbsp;
                      {iosData?.getPlatformSpecificLatestRelease?.appVersion ||
                        'N/A'}
                    </span>
                    <AppleFilled className="font-16" />
                  </Button>
                )}
              </Card>
            </Col>

            <Col md={12} lg={12} xl={7} xxl={7} className="android-release">
              <div className="latest-release-card">
                <div className="android-release">
                  <AndroidFilled className="android-color" />
                </div>

                <div className="releases">
                  <div className="total-release">
                    <div className="line-24">Total Releases</div>
                    <span className="line-24">
                      {androidData?.getPlatformSpecificLatestRelease
                        ?.platformSpecificDashboard
                        ?.totalPlatformSpecificUsersUpload || '0'}
                    </span>
                  </div>

                  <div className="total-downloads">
                    <div className="line-24">Total Downloads</div>
                    <span className="line-24">
                      {androidData?.getPlatformSpecificLatestRelease
                        ?.platformSpecificDashboard
                        ?.totalPlatformSpecificAppsDownload || '0'}
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={12} lg={12} xl={7} xxl={7} className="android-release">
              <div className="latest-release-card">
                <div className="android-release">
                  <AppleFilled className="ios-color" />
                </div>

                <div className="releases">
                  <div className="total-release">
                    <div className="line-24">Total Releases</div>
                    <span className="line-24">
                      {iosData?.getPlatformSpecificLatestRelease
                        ?.platformSpecificDashboard
                        ?.totalPlatformSpecificUsersUpload || '0'}
                    </span>
                  </div>

                  <div className="total-downloads">
                    <div className="line-24">Total Downloads</div>
                    <span className="line-24">
                      {iosData?.getPlatformSpecificLatestRelease
                        ?.platformSpecificDashboard
                        ?.totalPlatformSpecificAppsDownload || '0'}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default LatestRelease;
