import { Button, Result } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from './common/constants';

const Error404 = () => (
  <div className="gx-page-error-container error-404">
    <div className="gx-page-error-content">
      <Result
        status="404"
        title="404"
        subTitle="Oops, an error has occurred. Page not found!"
        extra={
          <Link className="gx-btn gx-btn-primary" to={ROUTES?.APPS}>
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    </div>
  </div>
);

export default Error404;
