import { useMutation } from '@apollo/client';
import { Button, Divider, Segmented } from 'antd';
import { capitalize } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import {
  ActivePlanDot,
  PopularBadge,
  SaveArrow,
  TickBlue,
  TickLightBlue,
} from '../../../../assets/svg';
import { GA_EVENT, GA_LABEL, PLANS } from '../../../../common/constants';
import { googleAnalyticsEvents } from '../../../../common/utils';
import { triggerPaddlePayment } from '../../common/paddlePaymentHandlers';
import {
  PURCHASE_SUBSCRIPTION,
  UPGRADE_DOWNGRADE_SUBSCRIPTION,
} from '../graphql/mutations';

function PricingOfPlans({
  monthlyPlans = [],
  yearlyPlans = [],
  handleVisible,
  paymentFeedback,
  setCountdownModalOpen,
}) {
  const {
    state: { teamId, teamPlan, isFreePlan, currentUser },
  } = useContext(AppContext);
  const [planType, setPlanType] = useState('Yearly billing');

  const [
    upgradeDowngradeSubscription,
    { loading: upgradeDowngradeLoading },
  ] = useMutation(UPGRADE_DOWNGRADE_SUBSCRIPTION, {
    onCompleted() {
      setCountdownModalOpen(true);
    },
    onError() {},
  });

  const [purchaseSubscription, { loading: purchaseLoading }] = useMutation(
    PURCHASE_SUBSCRIPTION,
    {
      onCompleted(res) {
        const { transactionId } = res?.purchaseSubscription?.data || {};
        triggerPaddlePayment(
          transactionId,
          (event) => {
            googleAnalyticsEvents(GA_EVENT?.PURCHASE_PLAN, {
              label: GA_LABEL?.PURCHASE_PLAN,
              member_id: currentUser?.id,
              purchase_plan_name_type: event?.data?.items?.[0]?.price_name,
            });
            // resolved callback
            paymentFeedback('success');
          },
          (status) => {
            if (status === 'closed') return;
            // reject callback
            paymentFeedback('failed');
          },
        );
      },
      onError() {},
    },
  );

  const onHandlePlanPurchase = (purchaseId) => {
    if (
      isFreePlan ||
      teamPlan?.subscriptionPlan?.name === PLANS?.LEGACY ||
      teamPlan?.name === PLANS?.LEGACY
    ) {
      purchaseSubscription({
        variables: {
          data: {
            id: purchaseId,
            teamId,
          },
        },
      });
    } else {
      upgradeDowngradeSubscription({
        variables: {
          data: {
            teamId,
          },
          where: {
            id: purchaseId,
          },
        },
      });
    }
  };

  const onChange = (key) => {
    setPlanType(key);
  };

  const handleContactClick = () => {
    googleAnalyticsEvents(GA_EVENT?.CONTACT_US, {
      label: GA_LABEL?.CONTACT_US,
      member_id: currentUser?.id,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });
    const email = 'support@appsonair.com';
    const subject = 'Enterprise inquire for custom plan';
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    // eslint-disable-next-line no-undef
    window.open(mailtoUrl, '_blank');
  };

  const renderPlanButton = (item) => {
    if (
      (item?.name === teamPlan?.subscriptionPlan?.name ||
        item?.name === teamPlan?.name) &&
      (item?.type === teamPlan?.subscriptionPlan?.type ||
        item?.type === teamPlan?.type)
    ) {
      return null;
    }
    if (
      item?.name === PLANS?.FREE &&
      (teamPlan?.subscriptionPlan?.name === PLANS?.LEGACY ||
        teamPlan?.name === PLANS?.LEGACY)
    ) {
      return null;
    }
    if (item?.name === PLANS?.PRO) {
      return (
        <Button
          loading={purchaseLoading || upgradeDowngradeLoading}
          type="primary"
          size="large"
          onClick={() => {
            googleAnalyticsEvents(GA_EVENT?.SELECT_PLAN, {
              label: GA_LABEL?.SELECT_PLAN,
              member_id: currentUser?.id,
              plan_name: item?.name,
              plan_type: item?.type,
              current_plan_name: teamPlan?.subscriptionPlan?.name,
              current_plan_type: teamPlan?.subscriptionPlan?.type,
            });
            onHandlePlanPurchase(item?.id);
          }}
        >
          Upgrade Now
        </Button>
      );
    }
    return (
      <Button
        type="primary"
        size="large"
        onClick={() => {
          handleVisible(true);
        }}
      >
        Downgrade Now
      </Button>
    );
  };

  const renderPlanCard = (item) => (
    <div
      key={item?.id}
      className={`free-plan ${item?.name === PLANS?.PRO ? 'pro' : ''}`}
    >
      {item?.subscriptionFeatures?.IS_POPULAR && (
        <div className="popular-ribbon">
          <PopularBadge /> <p>Popular</p>
        </div>
      )}
      {(item?.name === teamPlan?.subscriptionPlan?.name ||
        item?.name === teamPlan?.name) &&
        (item?.type === teamPlan?.subscriptionPlan?.type ||
          item?.type === teamPlan?.type) && (
          <div className="active-plan-ribbon">
            <ActivePlanDot /> <p>Active Plan</p>
          </div>
        )}
      <h3 className="title">{capitalize(item?.name)}</h3>
      <h2 className="price">
        {planType === 'Yearly billing'
          ? `$${item?.amount}`
          : `$${item?.amount}`}
        {item?.name === PLANS?.PRO && (
          <span className="small-text">
            {item?.type === 'ANNUAL' ? '/ year' : '/ month'}
          </span>
        )}
        {item?.name === PLANS?.PRO &&
          item?.type === 'ANNUAL' &&
          planType === 'Yearly billing' && (
            <span className="underline">
              ({item?.subscriptionFeatures?.ORIGINAL_PRICE})
            </span>
          )}
      </h2>
      <p className="description">{item?.description}</p>
      <Divider />
      <div className="list">
        {item?.name === PLANS?.PRO && <p>Everything in the Free plan, plus</p>}
        <ul>
          {item?.features?.map((feature) => (
            <li key={feature}>
              {item?.name === PLANS?.PRO ? <TickBlue /> : <TickLightBlue />}
              {feature}
            </li>
          ))}
        </ul>
      </div>
      {renderPlanButton(item)}
    </div>
  );

  useEffect(() => {
    setPlanType(
      teamPlan?.subscriptionPlan?.type === 'MONTHLY'
        ? 'Monthly billing'
        : 'Yearly billing',
    );
  }, [teamPlan]);

  return (
    <>
      <div className="header">
        {teamPlan?.subscriptionPlan?.name === PLANS?.PRO ||
        teamPlan?.subscriptionPlan?.name === PLANS?.LIFETIME ? (
          <h4 className="title">Pricing</h4>
        ) : (
          <>
            <h4 className="title">Discover Your Perfect Plan</h4>
            <p className="description">
              Elevate your experience with the perfect pricing option for you
            </p>
          </>
        )}
      </div>
      <div className="plans">
        <div className="tabs">
          <div className="tabs-block">
            <Segmented
              value={planType}
              options={['Monthly billing', 'Yearly billing']}
              onChange={onChange}
            />
            {planType === 'Yearly billing' && teamPlan?.name === PLANS?.FREE && (
              <div className="save-yearly">
                <SaveArrow /> <span className="save">Save 16%</span>
              </div>
            )}
          </div>
        </div>
        <div className="plans-cards">
          {planType === 'Monthly billing'
            ? monthlyPlans?.map((item) => renderPlanCard(item))
            : yearlyPlans?.map((item) => renderPlanCard(item))}
          <div className="free-plan">
            <h3 className="title">Enterprise</h3>
            <h2 className="price">Custom</h2>
            <p className="description">
              Request custom features and experience a plan with no limitations.
            </p>
            <Button
              size="large"
              className="default"
              onClick={handleContactClick}
            >
              Contact Us
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingOfPlans;
