import { CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { isAndroid, isIOS } from 'mobile-device-detect';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { LogoMain } from '../assets/svg';
import {
  DOWNLOAD_APPSTORE_URL,
  DOWNLOAD_PLAYSTORE_URL,
} from '../common/constants';

const SmartBanner = () => {
  const {
    state: { smartBanner },
    dispatch,
  } = useContext(AppContext);
  const [deviceType, setDeviceType] = useState(smartBanner);
  useEffect(() => {
    if (isAndroid) {
      setDeviceType('ANDROID');
    }

    if (isIOS) {
      setDeviceType('IOS');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="custom-smart-banner d-flex align-center font-poppins">
      <div
        className="mr-16 pointer"
        onClick={() => dispatch({ type: 'SET_SMART_BANNER', data: false })}
      >
        <CloseCircleOutlined />
      </div>
      <div className="mr-16">
        <LogoMain />
      </div>
      <div className="d-flex flex-vertical smart-banner-info">
        <span className="smart-banner-title font-bold label-m">AppsOnAir</span>
        <span className="smart-banner-author">Logicwind Technologies LLP</span>
      </div>
      <div className="ml-32 flex-1 d-flex justify-end ml-16">
        {deviceType === 'IOS' ? (
          <Button
            type="primary"
            className="button pointer"
            onClick={() => {
              dispatch({ type: 'SET_SMART_BANNER', data: false });
              // eslint-disable-next-line no-undef
              window?.open(DOWNLOAD_APPSTORE_URL, '_blank');
            }}
          >
            <span>View</span>
          </Button>
        ) : (
          <Button
            type="primary"
            className="button pointer"
            onClick={() => {
              dispatch({ type: 'SET_SMART_BANNER', data: false });
              // eslint-disable-next-line no-undef
              window?.open(DOWNLOAD_PLAYSTORE_URL, '_blank');
            }}
          >
            <span>View</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default SmartBanner;
