import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { LOGOUT_USER } from './graphql/mutations';

const Logout = () => {
  const [loading, setLoading] = useState(true);
  const { dispatch } = useContext(AppContext);
  const history = useHistory();

  const [logoutUser] = useMutation(LOGOUT_USER, {
    onCompleted(res) {
      if (res?.logoutUser) {
        dispatch({ type: 'LOGOUT' });
        history?.push(ROUTES?.LOGIN);
        setLoading(false);
      }
    },
    onError() {
      dispatch({ type: 'LOGOUT' });
      history?.push(ROUTES?.LOGIN);
      setLoading(false);
    },
  });

  useEffect(() => {
    logoutUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading && <LoaderComponent />;
};

export default Logout;
