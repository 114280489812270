import { Button, Dropdown, Modal } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import blankIcon from '../../../assets/images/blank-icon.png';
import downArrow from '../../../assets/svg/down-arrow.svg';
import { ROUTES } from '../../../common/constants';

const PaymentDueModal = ({
  visible,
  items,
  data,
  workspaceLogo,
  selectedIndex,
}) => {
  const history = useHistory();
  const {
    state: { teamId },
  } = useContext(AppContext);

  return (
    <Modal
      width={480}
      wrapClassName="payment-failed"
      title="Payment Failed"
      open={visible}
      footer={
        <Button
          type="primary"
          onClick={() => {
            history?.push(
              `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
            );
          }}
        >
          Manage Plans and payment
        </Button>
      }
      maskClosable={false}
      closable={false}
      centered
    >
      <div className="content">
        <div className="text">
          You cannot access anything due to the payment-due or failure. Update
          your plan to gain access again or switch to different workspace.
        </div>
        <div className="manage-button">
          <Dropdown
            overlayClassName="payment-due-workspace-dropdown"
            menu={{ items, selectable: true, defaultSelectedKeys: [teamId] }}
            trigger={['click']}
          >
            <Button className="workspace-dropdown">
              <div className="d-flex align-center">
                <img
                  className="team-logo"
                  alt="logo"
                  width={18}
                  height={18}
                  src={workspaceLogo || blankIcon}
                />
                <span
                  className="font-600 ml-8 mr-12"
                  title={
                    data?.getUserTeams?.[selectedIndex >= 0 ? selectedIndex : 0]
                      ?.teamName
                  }
                >
                  {
                    data?.getUserTeams?.[selectedIndex >= 0 ? selectedIndex : 0]
                      ?.teamName
                  }
                </span>
              </div>
              <img src={downArrow} alt="down-arrow" className="down-arrow" />
            </Button>
          </Dropdown>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentDueModal;
