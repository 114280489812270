import { Button, Modal } from 'antd';
import React from 'react';
import { MODAL_WIDTH } from '../../../common/constants';

const DeleteMemberFromAppModal = ({
  record,
  setLoading,
  setDeleteMemberModalVisible,
  deleteMemberModalVisible,
  handleDelete,
}) => {
  const handleCancel = () => {
    setDeleteMemberModalVisible(false);
  };

  const handleRemoveMember = () => {
    handleDelete();
    if (handleDelete) {
      setLoading(false);
      setDeleteMemberModalVisible(false);
    }
  };

  return (
    <div>
      <Modal
        title="Delete Member"
        width={MODAL_WIDTH}
        centered
        destroyOnClose
        wrapClassName="delete-member-modal"
        onCancel={handleCancel}
        open={deleteMemberModalVisible}
        footer={[
          <div key="footer-delete-member-from-app">
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn"
              type="primary"
              danger
              onClick={handleRemoveMember}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <div className="delete-member">
          <span>
            Are you sure you want to remove{' '}
            <b>
              {record?.first_name ||
                record?.invited_user_email ||
                record?.group_name}{' '}
              {record?.last_name}
            </b>{' '}
            ? They may not be able to access this file anymore.
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteMemberFromAppModal;
