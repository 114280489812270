import { Button, Col, Divider, Input, Row } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import {
  AddCircle,
  AppsAddOn,
  MinusCircle,
  StorageAddOn,
  TeamMembersAddOn,
} from '../../../../assets/svg';
import { ADD_ON, MAX_ADD_ONS_QUANTITY } from '../../../../common/constants';
import { currencyFormat } from '../../../../common/utils';

function AddonList({
  addOnsData = [],
  addOnsState = null,
  onHandleMinus,
  handleQuantityChange,
  onHandlePlus,
  handleAddOnPurchase,
}) {
  const {
    state: { teamPlanAddOns },
  } = useContext(AppContext);

  const containsTeamMembersAddon = teamPlanAddOns?.data?.some(
    (item) => item?.subscriptionPlan?.name === ADD_ON?.TEAM_MEMBERS,
  );

  const fetchIcons = (type) => {
    switch (type) {
      case ADD_ON?.APP:
        return <AppsAddOn />;
      case ADD_ON?.STORAGE:
        return <StorageAddOn />;
      case ADD_ON?.TEAM_MEMBERS:
        return <TeamMembersAddOn />;
      default:
        break;
    }
  };

  return (
    <div className="add-ons-cards">
      <Row gutter={[24, 24]}>
        {addOnsData?.map((item) => (
          <Col key={item?.id} span={8}>
            <div
              className={`add-ons-card ${
                item?.name === ADD_ON?.TEAM_MEMBERS && containsTeamMembersAddon
                  ? 'fade-feature'
                  : ''
              }`}
            >
              <div>
                <div className="d-flex justify-between align-center">
                  <h3 className="title m-0 d-flex gap-8 font-16 font-500">
                    {fetchIcons(item?.name)}
                    {item?.label}
                  </h3>
                  <p className="feature m-0">
                    {item?.subscriptionFeatures?.TEXT}
                  </p>
                </div>
                <p className="description">{item?.description}</p>
                <Divider />
              </div>
              <div>
                {item?.name !== ADD_ON?.TEAM_MEMBERS && (
                  <div className="quantity d-flex justify-between align-center">
                    <div>
                      <p className="feature-text m-0">
                        {item?.name === ADD_ON?.APP
                          ? 'Total apps'
                          : 'Total storage'}
                      </p>
                      <p className="feature-quantity m-0">
                        {addOnsState?.[item?.name]?.totalQuantity}
                      </p>
                    </div>
                    <div className="number">
                      <MinusCircle
                        className={
                          [0, 1].includes(addOnsState?.[item?.name]?.quantity)
                            ? 'disable-button'
                            : ''
                        }
                        onClick={() => {
                          onHandleMinus(item?.name);
                        }}
                      />
                      <Input
                        className="quantity-input"
                        defaultValue={1}
                        value={addOnsState?.[item?.name]?.quantity}
                        onChange={(e) => handleQuantityChange(e, item?.name)}
                      />
                      <AddCircle
                        className={
                          addOnsState?.[item?.name]?.quantity ===
                          MAX_ADD_ONS_QUANTITY
                            ? 'disable-button'
                            : ''
                        }
                        onClick={() => {
                          onHandlePlus(item?.name);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="total-bill d-flex justify-between align-end mt-16">
                  <div>
                    <h4 className="total mb-4">Total bill</h4>
                    <div className="total-amount d-flex">
                      <h4 className="price m-0">
                        {currencyFormat.format(
                          addOnsState?.[item?.name]?.total || 0,
                        )}
                      </h4>
                      <p className="month m-0">/ month</p>
                    </div>
                  </div>
                  <Button
                    loading={addOnsState?.[item?.name].loading}
                    type="primary"
                    disabled={[0].includes(addOnsState?.[item?.name]?.quantity)}
                    onClick={() => handleAddOnPurchase(item?.id, item?.name)}
                  >
                    Purchase
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default AddonList;
