import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Avatar, Button, Divider, Dropdown, Layout, Menu } from 'antd';
import { filter, findIndex } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import blankIcon from '../../../assets/images/blank-icon.png';
import { Logo, UpgradeCrown } from '../../../assets/svg';
import downArrow from '../../../assets/svg/down-arrow.svg';
import settingsIcon from '../../../assets/svg/settings-icon.svg';
import {
  GA_EVENT,
  GA_LABEL,
  PLANS,
  ROLE_KEYS,
  ROUTES,
  SUBSCRIPTION_STATUS,
  TEAM_KEY,
} from '../../../common/constants';
import { googleAnalyticsEvents } from '../../../common/utils';
import PaymentDueModal from '../../../modules/apps/components/PaymentDueModal';
import { GET_USER_TEAM } from '../../../modules/auth/graphql/queries';
import { MANAGE_ADD_ONS } from '../../../modules/workspace/manage-addons/graphql/queries';
import { CURRENT_PLAN_FOR_USER } from '../../../modules/workspace/plans-and-payment/graphql/queries';
import UserProfile from './UserProfile';
import './app-header.less';

const { Header } = Layout;

const AppHeader = () => {
  const location = useLocation();
  const setLocation = location?.pathname?.split('/');
  const history = useHistory();
  const {
    dispatch,
    state: {
      currentUser,
      ownerTeam,
      teamId,
      teamPlan,
      teamPlanAddOns,
      userTeams,
      fetchUserTeams,
      createNewTeam,
      refetchPlanUsage,
      isFreePlan,
      currentRole,
    },
  } = useContext(AppContext);
  const [selectedKey, setSelectedKey] = useState(teamId || null);
  const [paymentFailedModal, setPaymentFailedModal] = useState(false);

  const [getUserTeams, { data }] = useLazyQuery(GET_USER_TEAM, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      // eslint-disable-next-line no-undef
      const selectedTeamId = localStorage?.getItem(TEAM_KEY);
      const isTeamMatched = res?.getUserTeams?.filter(
        (team) => team?.id === selectedTeamId,
      );
      if (res?.getUserTeams?.length <= 0) {
        history?.push(ROUTES?.ONBOARD);
      } else {
        let value = '';
        const hasOwnerTeam = res?.getUserTeams?.some(
          (team) => team?.role === ROLE_KEYS?.OWNER,
        );
        dispatch({
          type: 'SET_OWNER_TEAM',
          data: hasOwnerTeam,
        });
        if (selectedTeamId && isTeamMatched?.length > 0) {
          dispatch({
            type: 'SET_TEAM',
            data: selectedTeamId,
          });
          value = filter(
            res?.getUserTeams,
            (val) => val?.id === selectedTeamId,
          );
          const prepareFeatureArray = value?.[0]?.teamConfig?.features;
          dispatch({
            type: 'SET_WORKSPACE_FEATURES',
            data: prepareFeatureArray,
          });
        } else {
          dispatch({
            type: 'SET_TEAM',
            data: res?.getUserTeams?.[0]?.id,
          });

          value = res?.getUserTeams?.[0];
        }
        dispatch({
          type: 'SET_USER_TEAMS',
          data: res?.getUserTeams,
        });
        dispatch({
          type: 'SET_CURRENT_ROLE',
          data: value,
        });
        if (createNewTeam) {
          setSelectedKey(res?.getUserTeams?.[0]?.id);
          dispatch({
            type: 'SET_NEW_TEAM',
            data: false,
          });
        }
        if (!selectedKey) {
          setSelectedKey(res?.getUserTeams?.[0]?.id);
        }
      }
    },
  });

  const [currentPlan] = useLazyQuery(CURRENT_PLAN_FOR_USER, {
    fetchPolicy: 'no-cache',
    variables: {
      data: {
        id: selectedKey,
      },
    },
    onCompleted(res) {
      const teamSubscription = res?.teamSubscriptionPlan?.data?.request;

      const teamConfig =
        res?.teamSubscriptionPlan?.data?.request?.team?.teamConfig?.config;
      dispatch({
        type: 'SET_TEAM_PLAN',
        data: teamSubscription,
      });

      const teamPlanTotal = {};

      teamConfig?.forEach((item) => {
        teamPlanTotal[item?.key] =
          item?.unit === 'GB' || item?.unit === 'MB'
            ? `${item?.value} ${item?.unit}`
            : item?.value;
      });

      dispatch({
        type: 'SET_TEAM_PLAN_USAGE',
        data: teamSubscription?.accountUsage,
      });

      dispatch({
        type: 'SET_TEAM_PLAN_TOTAL_USAGE',
        data: teamPlanTotal,
      });
      dispatch({
        type: 'SET_REFETCH_TEAM_PLAN_USAGE',
        data: false,
      });
      dispatch({
        type: 'SET_FREE_PLAN',
        data: teamSubscription?.name === PLANS?.FREE,
      });
    },
    onError() {},
  });

  const [currentAddOns] = useLazyQuery(MANAGE_ADD_ONS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.teamAddons) {
        dispatch({
          type: 'SET_TEAM_PLAN_ADDONS',
          data: res?.teamAddons,
        });
        dispatch({
          type: 'SET_REFETCH_TEAM_PLAN_USAGE',
          data: false,
        });
      }
    },
    onError() {},
  });

  useEffect(() => {
    if (location?.pathname?.includes('plan-payment')) {
      setPaymentFailedModal(false);
    } else if (
      teamPlan?.subscriptionStatus === SUBSCRIPTION_STATUS?.PAYMENT_DUE.key ||
      teamPlanAddOns?.data?.subscriptionStatus ===
        SUBSCRIPTION_STATUS?.PAYMENT_DUE.key
    ) {
      setPaymentFailedModal(true);
    }
  }, [location, teamPlan, teamPlanAddOns]);

  useEffect(() => {
    if (createNewTeam || fetchUserTeams) {
      getUserTeams();
      dispatch({
        type: 'SET_FETCH_USER_TEAMS',
        data: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNewTeam, fetchUserTeams]);

  useEffect(() => {
    getUserTeams();
    dispatch({
      type: 'SET_FETCH_USER_TEAMS',
      data: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const isWorkspace = location?.pathname?.includes(ROUTES?.WORKSPACE);
    if ((teamId !== 'null' && refetchPlanUsage) || !isWorkspace) {
      currentPlan({
        variables: {
          data: {
            id: teamId,
          },
        },
      });
      currentAddOns({
        variables: {
          data: {
            id: teamId,
          },
        },
      });
    }
  }, [teamId, refetchPlanUsage]);

  const handleMenuClick = (team, reload) => {
    currentPlan({
      variables: {
        data: {
          id: team?.id,
        },
      },
    });
    googleAnalyticsEvents(GA_EVENT?.CHANGE_WORKSPACE, {
      label: GA_LABEL?.CHANGE_WORKSPACE,
      workspace_id: team?.id,
      member_id: currentUser?.id,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });
    setSelectedKey(team?.id);
    const value = filter(userTeams, (val) => val?.id === team?.id);
    const prepareFeatureArray = value?.[0]?.teamConfig?.features;
    dispatch({
      type: 'SET_WORKSPACE_FEATURES',
      data: prepareFeatureArray,
    });
    dispatch({
      type: 'SET_CURRENT_ROLE',
      data: value,
    });
    dispatch({
      type: 'SET_TEAM',
      data: team?.id,
    });
    if (location?.pathname !== ROUTES?.APPS) {
      history?.push(ROUTES?.APPS);
    }
    if (reload) {
      // eslint-disable-next-line no-undef
      window.location.reload();
    }
  };

  const handleChange = (id) => {
    history?.push(
      `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.WORKSPACE_SETTINGS}/${id}`,
    );
  };

  const handleMenuChange = (key) => {
    switch (key?.key) {
      case 'apps':
        history?.push(ROUTES?.APPS);
        break;
      case 'teams':
        history?.push(ROUTES?.TEAMS);
        break;
      case 'integrations':
        history?.push(ROUTES?.INTEGRATIONS);
        break;
      default:
        history?.push(ROUTES?.APPS);
    }
  };

  const selectedIndex = findIndex(
    data?.getUserTeams,
    (item) => item?.id === teamId,
  );

  const workspaceLogo =
    data?.getUserTeams?.[selectedIndex >= 0 ? selectedIndex : '']?.teamLogo ||
    null;

  const items = [
    ...(data?.getUserTeams?.map((team) => ({
      key: team?.id,
      label: (
        <div className="d-flex align-center justify-between">
          <div className="team-name-col">
            <Avatar
              src={team?.teamLogo || blankIcon}
              alt="teamLogo"
              shape="square"
              className="team-avatar mr-4"
              size={18}
            />
            <span className="team-heading font-14" title={team?.teamName}>
              {team?.teamName}
            </span>
          </div>
          <div>
            <img
              src={settingsIcon}
              alt="icon"
              width={18}
              height={18}
              onClick={(e) => {
                e?.stopPropagation();
                handleChange(team?.id);
              }}
            />
          </div>
        </div>
      ),
      onClick: () => handleMenuClick(team),
    })) || []),
  ];

  const paymentDueItems = [
    ...(data?.getUserTeams?.map((team) => ({
      key: team?.id,
      label: (
        <div className="d-flex align-center justify-between">
          <div className="team-name-col">
            <Avatar
              src={team?.teamLogo || blankIcon}
              alt="teamLogo"
              shape="square"
              className="team-avatar mr-4"
              size={18}
            />
            <span className="team-heading font-14" title={team?.teamName}>
              {team?.teamName}
            </span>
          </div>
        </div>
      ),
      onClick: () => handleMenuClick(team, true),
    })) || []),
  ];

  const redirectLink = `${ROUTES.WORKSPACE_DETAILS}${ROUTES.PLAN_AND_PAYMENT}/${teamId}`;

  const headerItems = [
    { key: 'apps', label: 'Apps' },
    { key: 'teams', label: 'Team' },
    {
      key: 'integrations',
      label: (
        <div className="d-flex align-center gap-8">
          Integrations
          {(isFreePlan ||
            teamPlan?.subscriptionPlan?.name === PLANS?.LEGACY ||
            teamPlan?.name === PLANS?.LEGACY) && (
            <Button
              type="primary"
              size="small"
              className="upgrade-button"
              onClick={(e) => {
                e.stopPropagation();
                googleAnalyticsEvents(GA_EVENT?.PRO_RESTRICTION_BUTTON, {
                  label: GA_LABEL?.PRO_RESTRICTION_BUTTON,
                  member_id: currentUser?.id,
                  current_plan_name: teamPlan?.subscriptionPlan?.name,
                  current_plan_type: teamPlan?.subscriptionPlan?.type,
                });
                history?.push(redirectLink);
              }}
            >
              <span>Pro</span>
              <UpgradeCrown />
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <Header className="app-header">
      <div className="header-left">
        <div className="logo mr-8">
          <Logo onClick={() => history?.push(ROUTES?.APPS)} />
        </div>
        {data?.getUserTeams?.length > 0 &&
          !location?.pathname?.includes(ROUTES?.ONBOARD) && (
            <>
              <Divider type="vertical" className="app-header-divider" />
              <div className="header-left-content">
                <Menu
                  mode="horizontal"
                  selectedKeys={setLocation}
                  onClick={handleMenuChange}
                  items={headerItems}
                />
              </div>
            </>
          )}
      </div>
      <div className="header-right">
        {!location?.pathname?.includes(ROUTES?.ONBOARD) && (
          <>
            {data?.getUserTeams?.length > 0 &&
              currentRole?.[0]?.role === ROLE_KEYS?.OWNER &&
              isFreePlan && (
                <Button
                  type="primary"
                  className="upgrade-button"
                  onClick={() => {
                    googleAnalyticsEvents(GA_EVENT?.UPGRADE_BUTTON, {
                      label: GA_LABEL?.UPGRADE_BUTTON,
                      member_id: currentUser?.id,
                      current_plan_name: teamPlan?.subscriptionPlan?.name,
                      current_plan_type: teamPlan?.subscriptionPlan?.type,
                    });
                    history?.push(
                      `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                    );
                  }}
                >
                  <UpgradeCrown />
                  Upgrade
                </Button>
              )}
            {data?.getUserTeams?.length > 0 && (
              <>
                <Dropdown
                  overlayClassName={`dropdown-header ${
                    ownerTeam ? 'owner' : ''
                  }`}
                  menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: [teamId],
                  }}
                  trigger={['click']}
                  placement="topRight"
                  dropdownRender={(menu) => (
                    <div className="workspace-dropdown">
                      {React.cloneElement(menu)}
                      {(!ownerTeam ||
                        currentUser?.allowMultiWorkspaceCreation) && (
                        <div className="workspace-title sticky-button">
                          <Divider />
                          <Link
                            onClick={() => {
                              googleAnalyticsEvents(
                                GA_EVENT?.CREATE_NEW_WORKSPACE,
                                {
                                  label: GA_LABEL?.CREATE_NEW_WORKSPACE,
                                  member_id: currentUser?.id,
                                  current_plan_name:
                                    teamPlan?.subscriptionPlan?.name,
                                  current_plan_type:
                                    teamPlan?.subscriptionPlan?.type,
                                },
                              );
                              dispatch({ type: 'SET_CURRENT_VALUE', data: 0 });
                            }}
                            to={ROUTES?.ONBOARD}
                          >
                            <div className="create-workspace-content">
                              <PlusOutlined className="mr-8" />
                              <span className="text">Create Workspace</span>
                            </div>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                >
                  <Button type="text" className="workspace-dropdown">
                    <div className="d-flex align-center">
                      <img
                        className="team-logo"
                        alt="logo"
                        width={18}
                        height={18}
                        src={workspaceLogo || blankIcon}
                      />
                      <span
                        className="add-ellipsis font-600 ml-8 mr-12"
                        title={
                          data?.getUserTeams?.[
                            selectedIndex >= 0 ? selectedIndex : 0
                          ]?.teamName
                        }
                      >
                        {
                          data?.getUserTeams?.[
                            selectedIndex >= 0 ? selectedIndex : 0
                          ]?.teamName
                        }
                      </span>
                    </div>
                    <img
                      src={downArrow}
                      alt="down-arrow"
                      className="down-arrow"
                    />
                  </Button>
                </Dropdown>
                <Divider className="header-divider" type="vertical" />
              </>
            )}
            <div className="header-notification">
              <UserProfile />
            </div>
          </>
        )}
      </div>
      {paymentFailedModal && (
        <PaymentDueModal
          visible={paymentFailedModal}
          items={paymentDueItems}
          data={data}
          workspaceLogo={workspaceLogo}
          selectedIndex={selectedIndex}
        />
      )}
    </Header>
  );
};
export default AppHeader;
