import { Tabs } from 'antd';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './app-services.less';

const AppServices = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const setLocation = location?.pathname?.split('/');
  const appId = useParams();
  const id = appId?.appId;

  const handleTabChange = (key) => {
    switch (key) {
      case 'update':
        history?.push(
          `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}${ROUTES?.UPDATE}/${id}`,
        );
        break;

      case 'maintenance':
        history?.push(
          `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}${ROUTES?.MAINTENANCE}/${id}`,
        );
        break;

      case 'api-key':
        history?.push(
          `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}${ROUTES?.API_KEY}/${id}`,
        );
        break;

      default:
        history?.push(
          `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}${ROUTES?.UPDATE}/${id}`,
        );
    }
  };

  const items = [
    {
      key: 'update',
      label: 'Update',
      children,
    },
    {
      key: 'maintenance',
      label: 'Maintenance mode',
      children,
    },
    {
      key: 'api-key',
      label: 'API key',
      children,
    },
  ];

  return (
    <>
      <div className="services-header">
        <Tabs
          className="tabs-header"
          activeKey={setLocation?.[4]}
          onChange={handleTabChange}
          items={items}
        />
      </div>
    </>
  );
};

export default AppServices;
