import { Button, Modal } from 'antd';
import React from 'react';
import { MODAL_WIDTH } from '../../../common/constants';
import '../style/app-details.less';

const DeleteApp = ({
  showDeleteAppModal,
  handleVisible,
  mutation,
  deleteAppData,
  btnLoading,
}) => {
  const handleOk = () => {
    mutation();
    handleVisible(false);
  };
  const handleCancel = () => {
    handleVisible(false);
  };

  return (
    <div>
      <Modal
        centered
        width={MODAL_WIDTH}
        title="Delete App"
        onCancel={handleCancel}
        open={showDeleteAppModal}
        wrapClassName="delete-app-modal"
        onOk={handleOk}
        footer={[
          <div key="footer-delete-app">
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn"
              onClick={handleOk}
              type="primary"
              danger
              loading={btnLoading}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <div className="modal-content">
          <span>
            Are you sure you want to delete{' '}
            <span className="delete-app-name">
              {deleteAppData?.getApplication?.appName}
            </span>{' '}
            app? You will lose data of all the releases.
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteApp;
