import { gql } from '@apollo/client';

export const UPDATE_APPLICATION = gql`
  mutation updateApplication($data: AppUpdateInput!) {
    updateApplication(data: $data) {
      data {
        id
        appName
        appLogo
        createdAt
      }
      message
    }
  }
`;

export const REMOVE_APP_MEMBER = gql`
  mutation removeAppMember($referenceId: ID!, $appId: ID!) {
    removeAppMember(referenceId: $referenceId, appId: $appId) {
      message
    }
  }
`;

export const DELETE_RELEASE = gql`
  mutation deleteRelease($uniqueId: String!) {
    deleteRelease(uniqueId: $uniqueId) {
      message
    }
  }
`;

export const UPDATE_RELEASE_NOTE = gql`
  mutation updateReleaseNote($data: ReleaseUpdateInput!) {
    updateReleaseNote(data: $data) {
      message
    }
  }
`;

export const DELETE_APPLICATION = gql`
  mutation deleteApp($appId: ID!) {
    deleteApplication(appId: $appId) {
      message
    }
  }
`;

export const UPDATE_DOWNLOAD_COUNT = gql`
  mutation updateDownload($subLinkId: String, $applicationDetailId: ID) {
    updateDownloadCount(
      subLinkId: $subLinkId
      applicationDetailId: $applicationDetailId
    ) {
      message
    }
  }
`;

export const UPDATE_SHAREABLE_LINK = gql`
  mutation updateShareableLink($data: UpdateShareableLinkInput!) {
    updateShareableLink(data: $data) {
      message
    }
  }
`;

export const DELETE_SHARABLE_LINK = gql`
  mutation deleteSharableLink($uniqueId: String!, $subLinkId: ID!) {
    deleteShareableLink(uniqueId: $uniqueId, subLinkId: $subLinkId) {
      message
    }
  }
`;

export const INVITE_MEMBER_IN_APPLICATION = gql`
  mutation inviteMemberInApplication(
    $appId: ID!
    $data: [InviteMemberGroupInAppInput]!
  ) {
    inviteMemberInApplication(appId: $appId, data: $data) {
      message
    }
  }
`;

export const UPGRADE_APPLICATION = gql`
  mutation upgradeApplication($data: AppUpgradeApplication!) {
    upgradeApplication(data: $data) {
      message
    }
  }
`;

export const MAINTENANCE_APPLICATION = gql`
  mutation maintenanceApplication($data: AppMaintenanceInput!) {
    appMaintenance(data: $data) {
      message
    }
  }
`;

export const CREATE_SHAREABLE_LINK = gql`
  mutation createShareableLink($data: ShareableLinkInput!) {
    createShareableLink(data: $data) {
      message
      link
    }
  }
`;

export const RESOLVE_RELEASE_FEEDBACK = gql`
  mutation resolveReleaseFeedback($data: ResolveReleaseFeedbackInput!) {
    resolveReleaseFeedback(data: $data) {
      message
    }
  }
`;

export const ARCHIVE_DYNAMIC_LINK = gql`
  mutation archiveDynamicLink($where: DynamicLinkIdWhere!) {
    archiveDynamicLink(where: $where) {
      message
    }
  }
`;

export const DISABLE_DYNAMIC_LINK_CONFIG = gql`
  mutation disableDynamicLinkConfig($where: DynamicLinkConfigInput!) {
    disableDynamicLinkConfig(where: $where) {
      message
    }
  }
`;

export const CREATE_DYNAMIC_LINK = gql`
  mutation createDynamicLink(
    $where: DynamicLinkWhere!
    $data: DynamicLinkInput
  ) {
    createDynamicLink(where: $where, data: $data) {
      shortUrl
      message
    }
  }
`;

export const UPDATE_DYNAMIC_LINK = gql`
  mutation updateDynamicLink(
    $where: DynamicLinkIdWhere!
    $data: DynamicLinkInput
  ) {
    updateDynamicLink(where: $where, data: $data) {
      data {
        id
        name
        link
        shortId
        shortLink
        androidFallbackUrl
        iosFallbackUrl
        socialMetaTags
        clicks
        isEnable
        isIosApp
        iosAppId
        appstoreUrl
        iosUriSchema
        isAndroidApp
        androidPackageName
        playstoreUrl
        androidUriSchema
        defaultWebUrl
        isArchive
        archiveDate
        createdAt
      }
      message
    }
  }
`;

export const CREATE_DYNAMIC_LINK_CONFIG = gql`
  mutation createDynamicLinkConfig(
    $where: DynamicLinkConfigWhere!
    $data: CreateDynamicLinkConfigInput
  ) {
    createDynamicLinkConfig(where: $where, data: $data) {
      data {
        id
        createdBy
        applicationId
        isAndroidApp
        isIosApp
        androidPackageName
        playstoreUrl
        androidUriSchema
        iosAppId
        appstoreUrl
        iosUriSchema
        shaCertFingerprints
        defaultWebUrl
        customDomainIsEnable
        domainPrefix
        isCustomDomain
        urlPrefix
        customDomain
        isActive
        customDomainStatus
      }
      message
    }
  }
`;

export const UPDATE_DYNAMIC_LINK_CONFIG = gql`
  mutation updateDynamicLinkConfig(
    $where: DynamicLinkConfigInput!
    $data: UpdateDynamicLinkConfigInput
  ) {
    updateDynamicLinkConfig(where: $where, data: $data) {
      message
    }
  }
`;

export const VERIFY_DOMAIN = gql`
  mutation verifyDomain(
    $where: DynamicLinkConfigInput!
    $data: VerifyDomainInput!
  ) {
    verifyDomain(where: $where, data: $data) {
      customDomainStatus
    }
  }
`;

export const CHECK_DOMAIN_AVAILABILITY = gql`
  mutation CheckDomainAvailability($where: VerifyDomainInput!) {
    checkDomainAvailability(where: $where) {
      isAvailable
    }
  }
`;
