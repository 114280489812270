import { gql } from '@apollo/client';

export const GET_FEEDBACKS = gql`
  query feedbacks($filter: FeedbacksFilter!, $sort: FeedbacksSort) {
    feedbacks(filter: $filter, sort: $sort) {
      count
      feedbacks {
        id
        type
        description
        deviceInfo {
          releaseVersionNumber
        }
        createdAt
      }
    }
  }
`;

export const GET_FEEDBACK = gql`
  query Feedback($where: FeedbackWhereInput!) {
    feedback(where: $where) {
      id
      type
      description
      attachments {
        fileType
        key
      }
      appId
      userId
      userName
      deviceInfo {
        deviceModel
        deviceUsedStorage
        deviceTotalStorage
        deviceMemory
        appMemoryUsage
        deviceOsVersion
        deviceRegionCode
        deviceBatteryLevel
        deviceScreenSize
        deviceRegionName
        environment
        timezone
        appName
        releaseVersionNumber
        appsOnAirSDKVersion
        networkState
      }
      createdAt
    }
  }
`;
