import { Button, Card, Col, Progress, Row } from 'antd';
import { capitalize } from 'lodash';
import React, { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import { InfiniteIcon } from '../../../../assets/svg';
import { PLANS } from '../../../../common/constants';
import {
  calculatePercentageForProgress,
  convertSizeToGBOrMB,
  formatPurchaseDate,
} from '../../../../common/utils';

function PlansInfo({ handleCancelPlan, handleManageBilling }) {
  const {
    state: { teamPlan, teamPlanUsage, teamPlanTotalUsage },
  } = useContext(AppContext);
  return (
    <div className="current-plan-details">
      <Row gutter={24}>
        <Col span={8}>
          <Card title="Current Plan">
            <div className="blue-text d-flex justify-between align-center">
              {capitalize(
                teamPlan?.subscriptionPlan?.name || teamPlan?.name || '-',
              )}
              {(teamPlan?.subscriptionPlan?.name === PLANS?.PRO ||
                teamPlan?.subscriptionPlan?.name === PLANS?.LIFETIME) && (
                <Button onClick={handleCancelPlan} danger>
                  Cancel Plan
                </Button>
              )}
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Current Billing">
            <div className="blue-text d-flex justify-between align-center">
              ${teamPlan?.subscriptionPlan?.amount || teamPlan?.amount || 0}
              {(teamPlan?.subscriptionPlan?.name === PLANS?.PRO ||
                teamPlan?.subscriptionPlan?.name === PLANS?.LIFETIME) && (
                <Button onClick={handleManageBilling} type="primary">
                  Manage Billing
                </Button>
              )}
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title={
              <div className="d-flex justify-between">Next Payment Due</div>
            }
          >
            <div className="blue-text">
              {teamPlan?.subscriptionRenewalDate
                ? formatPurchaseDate(teamPlan?.subscriptionRenewalDate)
                : '-'}
            </div>
          </Card>
        </Col>
        <Col span={24} className="mt-32">
          <Card title="Account Usage" className="account-usage">
            <ul>
              <li>
                <span>Team Members</span>
                <span className="usage d-flex align-center justify-end gap-4">
                  {teamPlanUsage?.teamMembers} /{' '}
                  {teamPlanTotalUsage?.TEAM_MEMBERS === '-' ? (
                    <InfiniteIcon className="infinite-icon" />
                  ) : (
                    teamPlanTotalUsage?.TEAM_MEMBERS
                  )}
                </span>
              </li>
              <li>
                <span>Apps</span>
                <span className="usage">
                  {teamPlanUsage?.app || 0} / {teamPlanTotalUsage?.APP || 0}
                </span>
              </li>
              <li>
                <span>Storage</span>
                <span className="usage">
                  <Progress
                    status={
                      calculatePercentageForProgress(
                        teamPlanUsage?.storage,
                        teamPlanTotalUsage?.STORAGE,
                      ) > 90
                        ? 'exception'
                        : 'normal'
                    }
                    percent={calculatePercentageForProgress(
                      teamPlanUsage?.storage,
                      teamPlanTotalUsage?.STORAGE,
                    )}
                    format={() =>
                      `${convertSizeToGBOrMB(teamPlanUsage?.storage)} /
                    ${teamPlanTotalUsage?.STORAGE || 0}`
                    }
                  />
                </span>
              </li>
            </ul>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PlansInfo;
