import { Button, Modal } from 'antd';
import React from 'react';
import { MODAL_WIDTH } from '../../../common/constants';

const DeleteGroupModal = ({
  record,
  loading,
  deleteGroupModalVisible,
  setDeleteGroupModalVisible,
  handleDeleteGroup,
  editMember,
  setEditMember,
  getDetails,
  handleDeleteMemberFromGroup,
}) => {
  const handleCancel = () => {
    setDeleteGroupModalVisible(false);
    setEditMember(false);
  };

  return (
    <div>
      <Modal
        width={MODAL_WIDTH}
        title="Delete Group"
        centered
        destroyOnClose
        wrapClassName="delete-member-modal"
        onCancel={handleCancel}
        open={deleteGroupModalVisible}
        footer={
          <>
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn"
              type="primary"
              danger
              loading={loading}
              onClick={
                editMember ? handleDeleteMemberFromGroup : handleDeleteGroup
              }
            >
              Delete
            </Button>
          </>
        }
      >
        {editMember ? (
          <div className="delete-member">
            <span>
              Are you sure you want to remove{' '}
              <b>{getDetails?.firstName || getDetails?.invitedUserEmail}</b>{' '}
              from the group <b>{record?.groupName}</b> ?
            </span>
          </div>
        ) : (
          <div className="delete-member">
            <span>
              Are you sure you want to delete group <b>{record?.groupName}</b>?
            </span>
            <span>Deleting a group won't delete members in it.</span>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DeleteGroupModal;
