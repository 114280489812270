import { useQuery } from '@apollo/client';
import { Collapse } from 'antd';
import React from 'react';
import { ExpandIcon } from '../../../../assets/svg';
import { FAQS_LISTING } from '../graphql/queries';

function FaqSection() {
  const { data: { faqs: { data = [] } = {} } = {} } = useQuery(FAQS_LISTING, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  return (
    <div className="faq-section">
      <div className="header">
        <h2 className="title">Frequently Asked Questions</h2>
      </div>
      <div className="faq-list">
        {data?.map((item) => (
          <Collapse
            key={item?.id}
            expandIcon={() => (
              <div className="expand-icon">
                <ExpandIcon />
              </div>
            )}
            expandIconPosition="end"
            size="large"
            items={[
              {
                key: item?.id,
                label: item?.question,
                children: (
                  <div // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: item?.answer }}
                  />
                ),
              },
            ]}
          />
        ))}
      </div>
    </div>
  );
}

export default FaqSection;
