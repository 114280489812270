import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { App, ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Workbox } from 'workbox-window';
import { AppContextProvider } from './AppContext';
import Routes from './Routes';
import client from './apollo';
import AppContextHolder from './app/components/AppContextHolder';
import './index.css';
import './styles/main.less';
import themeJson from './styles/style.json';

const VERSION = require('../package.json').version;

// eslint-disable-next-line no-console
console?.log('APP_VERSION', VERSION);

// eslint-disable-next-line no-undef
if ('serviceWorker' in navigator) {
  const wb = new Workbox('/sw.js');

  wb.register();
}

Sentry?.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
});

// eslint-disable-next-line no-undef
const root = ReactDOM?.createRoot(document?.getElementById('root'));
root?.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <App>
        {/* Adding app context holder for messages, notification and modal [ant v5.12.1] */}
        <AppContextHolder />
        <ConfigProvider theme={themeJson}>
          <Routes />
        </ConfigProvider>
      </App>
    </AppContextProvider>
  </ApolloProvider>,
);
