import { useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { LIMIT } from '../../../common/constants';
import './billing-history.less';
import BillingHistoryTable from './components/BillingHistoryTable';
import { ADD_ONS_TRANSACTION_HISTORY } from './graphql/queries';

function BillingHistoryList() {
  const {
    state: { teamId },
  } = useContext(AppContext);
  const [selectedType, setSelectedType] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(null);

  const {
    data: { addonsTransactionHistory: data = [] } = {},
    loading,
  } = useQuery(ADD_ONS_TRANSACTION_HISTORY, {
    // changed network-only to no-cache as facing issue while getting subscription status
    // when used network-only i was getting 'ACTIVE' status but there is nothing like this in response of this query
    // only "SUCCESS" and "CANCELLED"
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: teamId,
      },
      filters: {
        limit: LIMIT,
        skip: (currentPage - 1) * LIMIT,
        type: selectedType,
        status: filter?.status || null,
      },
    },
  });

  const handlePageChange = (pagination, newFilter) => {
    setCurrentPage(pagination.current);
    setFilter({
      status: newFilter?.teamSubscription?.[0],
    });
    setSelectedType(newFilter?.subscriptionPlan?.[0]);
  };
  return (
    <>
      <div className="billing-history-module">
        <div className="d-flex justify-between">
          <h1 className="page-title">Billing History</h1>
          {/* kept for future use */}
          {/* <Select
            className="billing-type-select-input"
            value={selectedType}
            placeholder="Please select billing type"
            onSelect={(value) => {
              setSelectedType(value);
              setCurrentPage(1);
              setFilter({});
            }}
          >
            {map(BILLING_TYPE, (type) => (
              <Select.Option key={type.key}>{type.label}</Select.Option>
            ))}
          </Select> */}
        </div>
        <BillingHistoryTable
          data={data}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          loading={loading}
          setSelectedType={setSelectedType}
        />
      </div>
    </>
  );
}

export default BillingHistoryList;
