import { DownloadOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Radio, Table, Tooltip } from 'antd';
import { map } from 'lodash';
import React from 'react';
import {
  BILLING_TYPE,
  LIMIT,
  PLANS_TYPE,
  PLAN_NAMES,
} from '../../../../common/constants';
import { currencyFormat } from '../../../../common/utils';
import { GET_INVOICE_LINK } from '../graphql/queries';

const PLAN = 'PLAN';

function BillingHistoryTable({
  data = null,
  currentPage,
  onPageChange,
  loading,
}) {
  const [downloadInvoice] = useLazyQuery(GET_INVOICE_LINK, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const { url } = res?.getCustomerInvoiceLink;
      if (url) {
        // eslint-disable-next-line no-undef
        window.open(url);
      }
    },
    onError() {},
  });

  const getInvoice = (transactionId) => {
    if (!transactionId) return;
    downloadInvoice({
      variables: {
        where: {
          transactionId,
        },
      },
    });
  };

  const handleApplyFilters = (confirm) => {
    confirm();
  };

  const renderFilter = (keys) => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {map(keys, (category) => (
          <div key={category?.key}>
            <Radio
              checked={selectedKeys?.includes(category?.key)}
              onChange={(e) => {
                const updatedKeys = e?.target?.checked ? [category?.key] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {category?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => handleApplyFilters(confirm)}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'subscriptionPlan',
      key: 'subscriptionPlan',
      width: 150,
      render(value) {
        return PLAN_NAMES?.[value?.name] || '-';
      },
    },
    {
      title: 'Type',
      dataIndex: 'subscriptionPlan',
      key: 'subscriptionPlan',
      width: 100,
      render(value) {
        return (
          <p>
            {value?.mode === PLAN ? 'Plan' : BILLING_TYPE?.[value?.mode]?.label}
          </p>
        );
      },
      ...renderFilter(BILLING_TYPE),
    },
    {
      title: 'Frequency',
      dataIndex: 'subscriptionPlan',
      key: 'subscriptionPlan',
      width: 120,
      render(value) {
        return PLANS_TYPE?.[value?.type]?.label || '-';
      },
    },

    {
      title: 'Purchased Date',
      dataIndex: 'subscriptionStartDate',
      key: 'subscriptionStartDate',
      render(startDate) {
        return new Intl.DateTimeFormat('en', {
          dateStyle: 'short',
          timeStyle: 'short',
        })?.format(new Date(startDate));
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      align: 'center',
      render(value, record) {
        return record?.subscriptionPlan?.mode === PLAN ? '-' : value;
      },
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      align: 'center',
      render(value) {
        return currencyFormat?.format(value || 0);
      },
    },
    {
      title: '',
      dataIndex: 'transactionId',
      key: 'transactionId',
      width: 80,
      render: (value, record) => (
        <Tooltip title="Download invoice">
          <Button
            className="download-hover-btn"
            onClick={() =>
              record?.referenceData?.invoicePdf
                ? // eslint-disable-next-line no-undef
                  window.open(record?.referenceData?.invoicePdf)
                : getInvoice(value)
            }
          >
            <DownloadOutlined />
          </Button>
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="billing-history-list">
      <Table
        loading={loading}
        rowKey={(e) => e?.id}
        columns={columns}
        dataSource={data?.transactionHistory}
        pagination={{
          total: data?.count,
          pageSize: LIMIT,
          current: currentPage,
          showSizeChanger: false,
        }}
        onChange={onPageChange}
        scroll={{
          y: 'calc(100vh - 372px)',
        }}
        rootClassName={`billing-history-table ${
          data?.transactionHistory?.length > 0 ? 'add-min-hight' : ''
        }`}
      />
    </div>
  );
}

export default BillingHistoryTable;
