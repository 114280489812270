import { Modal, Progress } from 'antd';
import React, { useEffect, useState } from 'react';

const CountdownModal = ({ countdownModalOpen }) => {
  const [percent, setPercent] = useState(0);
  const totalDuration = 15000;

  const closeModalWithTimeout = () => {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      window?.location?.reload();
    }, totalDuration);
  };

  useEffect(() => {
    if (countdownModalOpen) {
      closeModalWithTimeout();
    }
  }, [countdownModalOpen]);

  useEffect(() => {
    let progress = 0;
    let remainingTime = 14;

    const interval = setInterval(() => {
      progress += 100 / 14;
      setPercent(Math.round(progress));

      remainingTime -= 1;

      if (remainingTime <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Modal
        centered
        wrapClassName="countdown-modal"
        open={countdownModalOpen}
        closable={false}
        footer={null}
      >
        <Progress type="circle" percent={percent} />
        <h3>Please wait, changes are reflecting...</h3>
        If changes won't reflected please contact us through support
      </Modal>
    </div>
  );
};

export default CountdownModal;
