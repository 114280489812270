import { gql } from '@apollo/client';

export const GET_TEAM = gql`
  query getTeam($teamId: ID!) {
    getTeam(teamId: $teamId) {
      teamDetails {
        id
        teamName
        teamLogo
        teamCreator {
          firstName
          lastName
          email
          profileImage
        }
        allowFeedbacks
        isLegacy
      }
      count
    }
  }
`;

export const GET_USERS_TEAM = gql`
  query getUsersTeam {
    getUserTeams {
      id
      teamName
      teamLogo
      role
    }
  }
`;

export const GET_MEMBER_APPS_LIST = gql`
  query getMemberAppsList($data: MemberAppsListFilter!) {
    getMemberAppsList(data: $data) {
      appsDetails {
        id
        role
        invitedUserEmail
        firstName
        lastName
        profileImage
        userId
        userRegisterStatus
        applicationList {
          id
          appName
          appLogo
        }
        invitedByDetails {
          firstName
          lastName
          profileImage
        }
        applicationCount
        groupDetails {
          id
          groupName
        }
      }
      count
    }
  }
`;

export const GET_GROUP_MEMBERS_LIST = gql`
  query getGroupMembersList($data: GroupMemberFilter!) {
    getGroupMembersList(data: $data) {
      groupMembers {
        id
        firstName
        lastName
        profileImage
        userRegisterStatus
        invitedUserEmail
        role
        userId
      }
      membersCount
    }
  }
`;

export const GET_GROUP_LIST = gql`
  query getGroupsList($data: GroupFilter!) {
    getGroupsList(data: $data) {
      group {
        id
        groupName
      }
      totalGroups
    }
  }
`;

export const GET_GROUP_LIST_WITH_APP = gql`
  query getGroupsListWithApps($data: GroupFilter!) {
    getGroupsListWithApps(data: $data) {
      totalGroups
      groupsAppsDetails {
        id
        groupName
        applicationList {
          appName
          appLogo
          id
        }
      }
    }
  }
`;

export const GET_GROUP_APPS = gql`
  query getGroupApps($groupId: ID!, $search: String) {
    getGroupApps(groupId: $groupId, search: $search) {
      groupApps {
        id
        appName
        appLogo
      }
      count
    }
  }
`;
