import { useMutation, useQuery } from '@apollo/client';
import { Button, Result } from 'antd';
import { isMobile, isSafari } from 'mobile-device-detect';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { messageContext } from '../../app/components/AppContextHolder';
import noLink from '../../assets/svg/no-link.svg';
import {
  DOWNLOAD_IOS_APP_URL,
  GA_EVENT,
  GA_LABEL,
} from '../../common/constants';
import { copyToClipboard, googleAnalyticsEvents } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import { UPDATE_DOWNLOAD_COUNT } from '../apps/graphql/mutations';
import DesktopDetailPage from './DesktopDetailPage';
import MobileDetailPage from './MobileDetailPage';
import { GET_APP_DETAIL } from './graphql/queries';

const Detail = ({ password }) => {
  const appID = useParams();
  const { subLinkId } = appID;
  // eslint-disable-next-line no-undef
  const link = window?.location?.href;

  const getAppInput = {
    subLinkId,
    password,
  };
  const [linkExpired, setLinkExpired] = useState(false);
  const [errorType, setErrorType] = useState('');

  // eslint-disable-next-line no-undef
  const isMobileScreen = !(window?.innerWidth > 768);

  const { data, loading } = useQuery(GET_APP_DETAIL, {
    fetchPolicy: 'network-only',
    variables: {
      getAppInput,
    },
    onError(error) {
      if (error?.graphQLErrors?.[0]?.extensions?.code === 'LINK_EXPIRED') {
        setLinkExpired(true);
      } else if (
        error?.graphQLErrors?.[0]?.extensions?.code === 'LINK_NOT_FOUND'
      ) {
        setErrorType('LINK_NOT_FOUND');
      } else {
        setErrorType('SOMETHING_WENT_WRONG');
      }
    },
  });

  const [downloadCounter] = useMutation(UPDATE_DOWNLOAD_COUNT, {
    onError(err) {
      return err;
    },
  });

  const downloadBtn = () => {
    googleAnalyticsEvents(GA_EVENT?.SHARE_APPLICATION_DOWNLOAD, {
      label: GA_LABEL?.SHARE_APPLICATION_DOWNLOAD,
    });
    setTimeout(() => {
      downloadCounter({
        variables: {
          subLinkId,
        },
      });
    }, 500);
  };

  const copyUniqueId = () => {
    copyToClipboard(link);
    messageContext?.success('Link copied to clipboard');
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <>
      {linkExpired === true ? (
        <div className="empty-no-link-image d-flex justify-center">
          <div className="d-flex justify-center">
            <img src={noLink} alt="no-link" className="empty-image" />
          </div>
        </div>
      ) : (
        <>
          {
            // eslint-disable-next-line no-nested-ternary
            errorType === 'LINK_NOT_FOUND' ? (
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
              />
            ) : errorType === 'SOMETHING_WENT_WRONG' ? (
              <Result
                status="500"
                title="Sorry, something went wrong."
                subTitle="Click on reload page button to try again."
                extra={
                  <Button
                    type="primary"
                    onClick={() => {
                      // eslint-disable-next-line no-undef
                      window.location.reload();
                    }}
                  >
                    Reload Page
                  </Button>
                }
              />
            ) : (
              <>
                {!isMobileScreen && (
                  <DesktopDetailPage
                    data={data}
                    link={link}
                    subLinkId={subLinkId}
                    copyUniqueId={copyUniqueId}
                    appSize={parseFloat(
                      data?.getApp?.appData?.appSize,
                    )?.toFixed(2)}
                    appStorageUrl={
                      isSafari &&
                      isMobile &&
                      data?.getApp?.appData?.plistStorageUrl
                        ? `${DOWNLOAD_IOS_APP_URL}${data?.getApp?.appData?.plistStorageUrl}`
                        : `${data?.getApp?.appData?.appStorageUrl}`
                    }
                    downloadBtn={downloadBtn}
                  />
                )}
                {isMobileScreen && (
                  <MobileDetailPage
                    data={data}
                    link={link}
                    subLinkId={subLinkId}
                    copyUniqueId={copyUniqueId}
                    appSize={parseFloat(
                      data?.getApp?.appData?.appSize,
                    )?.toFixed(2)}
                    appStorageUrl={
                      isSafari &&
                      isMobile &&
                      data?.getApp?.appData?.plistStorageUrl
                        ? `${DOWNLOAD_IOS_APP_URL}${data?.getApp?.appData?.plistStorageUrl}`
                        : `${data?.getApp?.appData?.appStorageUrl}`
                    }
                    downloadBtn={downloadBtn}
                  />
                )}
              </>
            )
          }
        </>
      )}
    </>
  );
};

export default Detail;
