import { Button, Modal } from 'antd';
import React from 'react';
import '../manage-addon.less';

const PurchaseAgainModal = ({
  visible,
  setVisible,
  handleAgainPurchase,
  handleAgainCancel,
}) => {
  const handleOk = () => {
    handleAgainPurchase();
    setVisible(false);
  };

  const handleCancel = () => {
    handleAgainCancel();
    setVisible(false);
  };

  return (
    <div>
      <Modal
        centered
        className="purchase-again-add-ons"
        title="Are you sure you want to purchase the add-ons?"
        width={480}
        open={visible}
        closable={false}
        maskClosable={false}
        onOk={handleOk}
        footer={[
          <div key="footer-delete-release">
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleOk} type="primary">
              Yes,purchase
            </Button>
          </div>,
        ]}
      >
        <div className="modal-content">
          <div>
            The amount will be deducted directly from your current payment
            method.
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PurchaseAgainModal;
