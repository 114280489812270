import React, { createContext, useReducer } from 'react';
import client from './apollo';
import api from './common/api';
import {
  APPLICATION_ID,
  CURRENT_EMAIL,
  REFRESH_TOKEN,
  SMART_BANNER_OPEN,
  TEAM_KEY,
  TOKEN,
  USER,
} from './common/constants';

const initialState = {
  currentUser: null,
  // eslint-disable-next-line no-undef
  authToken: localStorage?.getItem(TOKEN),
  isFreePlan: false,
  ownerTeam: false,
  // eslint-disable-next-line no-undef
  teamId: localStorage?.getItem(TEAM_KEY),
  teamPlanAddOns: [],
  teamPlan: [],
  teamPlanUsage: [],
  refetchPlanUsage: true,
  teamPlanTotalUsage: [],
  // eslint-disable-next-line no-undef
  applicationId: localStorage?.getItem(APPLICATION_ID),
  // eslint-disable-next-line no-undef
  createNewTeam: false,
  // eslint-disable-next-line no-undef
  userEmail: localStorage?.getItem(CURRENT_EMAIL),
  loading: true,
  currentRole: null,
  userTeams: [],
  fetchUserTeams: false,
  workspaceFeatures: [],
  // eslint-disable-next-line no-undef
  smartBanner: !localStorage?.getItem(SMART_BANNER_OPEN),
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action?.data || {};
      // eslint-disable-next-line no-undef
      localStorage?.setItem(
        USER,
        user && Object?.keys(user)?.length ? JSON?.stringify(user) : null,
      );
      return { ...state, currentUser: { ...user } };
    case 'SET_CURRENT_ROLE':
      return { ...state, currentRole: action?.data };
    case 'LOGOUT':
      delete api?.defaults?.headers?.common?.Authorization;
      // eslint-disable-next-line no-undef
      localStorage?.clear();
      client?.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {},
      };
    case 'SET_FETCHING_USER_DETAILS':
      return { ...state, fetchingUserDetails: action?.data };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action?.data };
    case 'SET_APP_RELEASE_DEFAULT_LINK':
      return { ...state, releaseDefaultLink: action?.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(TOKEN, action?.data);
      return { ...state, authToken: action?.data };
    case 'SET_REFRESH_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(REFRESH_TOKEN, action?.data);
      return { ...state, authToken: action?.data };
    case 'SET_INITIAL_SHOW_ALL_FIELDS_STATUS':
      return { ...state, initialShowAllFieldStatus: action?.data };
    case 'SET_TEAM':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(TEAM_KEY, action?.data);
      return { ...state, teamId: action?.data };
    case 'SET_FREE_PLAN':
      return { ...state, isFreePlan: action?.data };
    case 'SET_OWNER_TEAM':
      return { ...state, ownerTeam: action?.data };
    case 'SET_TEAM_PLAN_ADDONS':
      return { ...state, teamPlanAddOns: action?.data };
    case 'SET_TEAM_PLAN':
      return { ...state, teamPlan: action?.data };
    case 'SET_REFETCH_TEAM_PLAN_USAGE':
      return { ...state, refetchPlanUsage: action?.data };
    case 'SET_TEAM_PLAN_USAGE':
      return { ...state, teamPlanUsage: action?.data };
    case 'SET_TEAM_PLAN_TOTAL_USAGE':
      return { ...state, teamPlanTotalUsage: action?.data };
    case 'SET_APPLICATION_ID':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(APPLICATION_ID, action?.data);
      return { ...state, applicationId: action?.data };
    case 'SET_NEW_TEAM':
      return { ...state, createNewTeam: action?.data };
    case 'SET_EMAIL':
      return { ...state, userEmail: action?.data };
    case 'SET_PROFILE_LOGO':
      return { ...state, profileLogo: action?.data };
    case 'SET_LOADING':
      return { ...state, loading: action?.data };
    case 'SET_USER_TEAMS':
      return { ...state, userTeams: action?.data };
    case 'SET_FETCH_USER_TEAMS':
      return { ...state, fetchUserTeams: action?.data };
    case 'SET_SMART_BANNER':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(SMART_BANNER_OPEN, action?.data);
      return { ...state, smartBanner: action?.data };
    case 'SET_WORKSPACE_FEATURES':
      return {
        ...state,
        workspaceFeatures: action?.data
          ?.filter((item) => item?.isActive)
          ?.map((element) => element?.key),
      };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const getToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(TOKEN) || null;
  const getRefreshToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(REFRESH_TOKEN) || null;
  const getCurrentUser = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(USER)
      ? // eslint-disable-next-line no-undef
        JSON?.parse(localStorage?.getItem(USER))
      : {};
  const isAuthenticated = () => state?.authenticated;

  const initializeAuth = (authToken, userData, userRefreshToken) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    const refreshToken = userRefreshToken || getRefreshToken();
    const workspace = user?.defaultWorkspace;
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_REFRESH_TOKEN', data: refreshToken });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
      dispatch({ type: 'SET_CURRENT_USER', data: user });
      dispatch({ type: 'SET_TEAM', data: workspace });
      if (user?.roles?.[0]) {
        dispatch({ type: 'SET_CURRENT_ROLE', data: user?.roles[0] });
      }
    }
  };

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    initializeAuth,
    getCurrentUser,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext?.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };
