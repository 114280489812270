import { SettingOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Col, Popconfirm, Row, Switch, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { AppContext } from '../../AppContext';
import { UpgradeCrown } from '../../assets/svg';
import {
  ACTIONS,
  GA_EVENT,
  GA_LABEL,
  PLANS,
  ROUTES,
  TEAM_KEY,
} from '../../common/constants';
import { googleAnalyticsEvents } from '../../common/utils';
import AccessControl from '../../components/AccessControl';
import LoaderComponent from '../../components/LoaderComponent';
import AppConnectPopup from './components/AppConnectPopup';
import {
  DELETE_INTEGRATION,
  UPDATE_INTEGRATION,
  VERIFY_INTEGRATION_AUTH_CODE,
} from './graphql/mutations';
import {
  GET_INTEGRATIONS_LIST,
  GET_INTEGRATION_AUTH_URL,
} from './graphql/queries';
import './style/integration.less';

const { Title } = Typography;
function Integrations() {
  const {
    state: { isFreePlan, teamPlan },
  } = useContext(AppContext);
  const history = useHistory();
  const { search } = useLocation();
  const searchUrl = new URLSearchParams(search);
  const platformQuery = searchUrl?.get('platform');
  const codeQueryParameter = searchUrl?.get('code');
  // eslint-disable-next-line no-undef
  const teamId = localStorage?.getItem(TEAM_KEY);
  const [loader, setLoader] = useState(true);
  const [integrationList, setIntegrationList] = useState([]);
  const [appConnectPopup, setAppConnectPopup] = useState({
    isPopupOpen: false,
    isEdit: false,
    popupData: {},
  });
  const [selectedDeletePopupId, setSelectedDeletePopupId] = useState(null);

  const [getIntegrationList] = useLazyQuery(GET_INTEGRATIONS_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      where: { teamId },
    },
    onCompleted: (res) => {
      setLoader(false);
      setIntegrationList(res?.getConfiguredIntegrations?.data);
      if (codeQueryParameter && platformQuery) {
        const integrationListData = res?.getConfiguredIntegrations?.data;
        setAppConnectPopup({
          isPopupOpen: true,
          isEdit: true,
          popupData:
            integrationListData[
              integrationListData?.findIndex(
                (item) => item?.key === platformQuery,
              )
            ],
        });
        history?.replace(ROUTES?.INTEGRATIONS);
      }
    },
    onError() {
      setLoader(false);
    },
  });

  const [updateIntegrationConfig] = useMutation(UPDATE_INTEGRATION, {
    onCompleted() {
      getIntegrationList();
      setLoader(false);
    },
    onError() {
      getIntegrationList();
      setLoader(false);
    },
  });

  const [deleteIntegration] = useMutation(DELETE_INTEGRATION, {
    /* no-cache added due to mutation is not triggered */
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setSelectedDeletePopupId(null);
      getIntegrationList();
    },
    onError() {
      setLoader(false);
    },
  });

  const [getIntegrationAuthUrl] = useLazyQuery(GET_INTEGRATION_AUTH_URL, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      // eslint-disable-next-line no-undef
      window.location.href = res?.getIntegrationAuthUrl?.url;
      setLoader(false);
    },
    onError() {
      setLoader(false);
    },
  });

  const [verifyAuthUrl] = useMutation(VERIFY_INTEGRATION_AUTH_CODE, {
    /* no-cache added due to mutation is not triggered */
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      getIntegrationList();
      setLoader(false);
    },
    onError() {
      /* if verification failed remove all query parameter */
      history?.replace(ROUTES?.INTEGRATIONS);
      setLoader(false);
    },
  });

  useEffect(() => {
    if (codeQueryParameter && platformQuery) {
      verifyAuthUrl({
        variables: {
          platform: platformQuery,
          authorizationCode: codeQueryParameter,
          teamId,
        },
      });
    } else {
      getIntegrationList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasIntegrationSettingsPermission = AccessControl({
    allowedPermissions: ACTIONS?.INTEGRATION_SETTINGS,
  });

  const handleIntegration = (event, integrationData) => {
    if (
      !(
        isFreePlan ||
        teamPlan?.subscriptionPlan?.name === PLANS?.LEGACY ||
        teamPlan?.name === PLANS?.LEGACY
      )
    ) {
      if (event) {
        if (integrationData?.isConfigured) {
          updateIntegrationConfig({
            variables: {
              data: {
                isActive: true,
              },
              where: {
                integrationConfigId:
                  integrationData?.integrationConfigs?.[0].id,
              },
            },
          });
        } else if (integrationData?.action === 'POPUP') {
          setAppConnectPopup({
            isPopupOpen: event,
            popupData: integrationData,
            isEdit: false,
          });
        } else {
          /* handling redirection based integration services */
          getIntegrationAuthUrl({
            variables: {
              platform: integrationData?.key,
              teamId,
            },
          });
        }
      } else {
        setSelectedDeletePopupId(integrationData?.id);
      }
    }
  };

  const handleDeletePopConfirm = (index) => {
    const configId = integrationList?.[index].integrationConfigs?.[0].id;
    deleteIntegration({
      variables: {
        where: {
          integrationConfigId: configId,
        },
      },
    });
  };

  return (
    <>
      <div className="integration">
        <Title className="title">Integrations</Title>
        <p className="description">Connect AppsOnAir to your favorite apps</p>
        {(isFreePlan ||
          teamPlan?.subscriptionPlan?.name === PLANS?.LEGACY ||
          teamPlan?.name === PLANS?.LEGACY) && (
          <div className="app-service-pro-banner">
            <div className="description-section">
              <h2>Get Integrations feature</h2>
              <p>Upgrade to enable Integrations to your apps.</p>
            </div>
            <div className="button-section">
              <Button
                type="primary"
                className="upgrade-button"
                onClick={() => {
                  googleAnalyticsEvents(GA_EVENT?.INTEGRATION_UPGRADE_TO_PRO, {
                    label: GA_LABEL?.INTEGRATION_UPGRADE_TO_PRO,
                    current_plan_name: teamPlan?.subscriptionPlan?.name,
                    current_plan_type: teamPlan?.subscriptionPlan?.type,
                  });
                  history?.push(
                    `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                  );
                }}
              >
                <UpgradeCrown />
                Upgrade to pro
              </Button>
            </div>
          </div>
        )}
        {loader ? (
          <LoaderComponent setHeight="500" />
        ) : (
          <div
            className={`app-list ${
              isFreePlan ||
              teamPlan?.subscriptionPlan?.name === PLANS?.LEGACY ||
              teamPlan?.name === PLANS?.LEGACY
                ? 'fade-feature'
                : ''
            }`}
          >
            <Row className="app-card-row" gutter={[16, 24]}>
              {integrationList?.map((item, index) => (
                <Col key={item?.name} sm={24} md={12} lg={12} xl={8} xxl={6}>
                  <div>
                    <Card key={item?.name} className="app-list-card">
                      <div className="service-info">
                        <div>
                          <img
                            className="service-img"
                            src={item?.logo}
                            alt={item?.name}
                          />
                        </div>
                        <div>
                          {hasIntegrationSettingsPermission &&
                            item?.isConfigured && (
                              <SettingOutlined
                                onClick={() =>
                                  setAppConnectPopup({
                                    isPopupOpen: true,
                                    popupData: item,
                                    isEdit: true,
                                  })
                                }
                                className="setting-icon"
                              />
                            )}
                          <Popconfirm
                            title="This will remove integration, Are you sure?"
                            okText="Yes"
                            cancelText="No"
                            overlayClassName="custom-popconfirm"
                            getPopupContainer={(trigger) => trigger?.parentNode}
                            open={Boolean(selectedDeletePopupId === item?.id)}
                            onConfirm={() => {
                              handleDeletePopConfirm(index);
                            }}
                            onCancel={() => setSelectedDeletePopupId(null)}
                          >
                            <Switch
                              checked={item?.integrationConfigs?.some(
                                (config) => config.isActive,
                              )}
                              onChange={(e) => handleIntegration(e, item)}
                              disabled={!hasIntegrationSettingsPermission}
                            />
                          </Popconfirm>
                        </div>
                      </div>
                      <Title className="inner-title">{item?.name}</Title>
                      <p className="inner-desc">{item?.description}</p>
                    </Card>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
        <AppConnectPopup
          appConnectPopupData={appConnectPopup}
          handleClose={() =>
            setAppConnectPopup({
              isPopupOpen: false,
              popupData: {},
              isEdit: false,
            })
          }
          teamId={teamId}
          getIntegrationList={getIntegrationList}
        />
      </div>
    </>
  );
}

export default Integrations;
