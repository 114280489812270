import { Button, Modal } from 'antd';
import { capitalize } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import { MODAL_WIDTH } from '../../../../common/constants';
import '../plan-and-payment.less';

const CancelSubsCriptionModal = ({
  visible,
  handleVisible,
  cancelSubscription,
  isPlansInfoLoading,
}) => {
  const {
    state: { teamPlan },
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const handleOk = async () => {
    setLoading(true);
    isPlansInfoLoading(true);
    await cancelSubscription({
      variables: {
        data: {
          id: teamPlan?.id,
        },
      },
    });
    setLoading(false);
    handleVisible(false);
  };

  const handleCancel = () => {
    handleVisible(false);
  };

  return (
    <div>
      <Modal
        centered
        className="cancel-add-ons"
        title="Cancel subscription"
        width={MODAL_WIDTH}
        onCancel={handleCancel}
        open={visible}
        onOk={handleOk}
        footer={[
          <div key="footer-delete-release">
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn"
              onClick={handleOk}
              type="primary"
              danger
              loading={loading}
            >
              Okay
            </Button>
          </div>,
        ]}
      >
        <div className="modal-content">
          <div>
            {`Are you sure you want to cancel your `}
            <strong>
              {capitalize(teamPlan?.subscriptionPlan?.name || teamPlan?.name)}
            </strong>
            {` subscription?`}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancelSubsCriptionModal;
