import { initializePaddle } from '@paddle/paddle-js';
import { PAYMENT_CHECKOUT } from '../../../common/constants';

// eslint-disable-next-line import/prefer-default-export
export const triggerPaddlePayment = (transactionId, resolve, reject) => {
  let paddleObj;
  initializePaddle({
    environment: process.env.REACT_APP_PADDLE_ENVIRONMENT,
    token: process.env.REACT_APP_PADDLE_CLIENT_TOKEN,
    eventCallback: async (event) => {
      if (
        event?.name === PAYMENT_CHECKOUT.CHECKOUT_CLOSED &&
        (event?.data?.status === PAYMENT_CHECKOUT.STATUS.DRAFT ||
          event?.data?.status === PAYMENT_CHECKOUT.STATUS.READY)
      ) {
        if (paddleObj) {
          paddleObj?.Checkout?.close();
        }
        if (event?.name === PAYMENT_CHECKOUT.CHECKOUT_CLOSED) {
          reject('closed');
        } else {
          reject();
        }
      }

      if (event?.data?.status === PAYMENT_CHECKOUT.STATUS.COMPLETED) {
        if (event?.name === PAYMENT_CHECKOUT.CHECKOUT_COMPLETED) {
          if (paddleObj) {
            paddleObj?.Checkout?.close();
          }
          resolve(event);
        }
      }
    },
  }).then((Paddle) => {
    Paddle.Checkout.open({
      transactionId,
    });
    paddleObj = Paddle;
  });
};
