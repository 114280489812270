import { Button, Modal } from 'antd';
import React from 'react';
import {
  ApplicationCountRestriction,
  StorageRestriction,
  TeamRestriction,
} from '../../../../assets/svg';
import '../../plans-and-payment/plan-and-payment.less';

const CancelAddonRestrictionModal = ({
  visible,
  handleVisible,
  selectedAddOn,
}) => {
  const addonsContent = [
    {
      key: 'APP',
      title: 'Manage Applications to Stay Within Add-ons Limits',
      content: (
        <div>
          <div>
            <ApplicationCountRestriction />
          </div>
          <div className="text-area">
            <div>
              <h3>Check Application Count:</h3>
              <p>
                Ensure the number of applications does not exceed the limit of
                the Add-ons.
                <br />
                For example, If the allowed limit is 1 application and the user
                has 5 applications, they need to delete 4 applications to meet
                the limit.
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'STORAGE',
      title: 'Optimize Storage by Managing App Releases',
      content: (
        <div>
          <div>
            <StorageRestriction />
          </div>
          <div className="text-area">
            <div>
              <h3>Manage App Releases to Reduce Storage:</h3>
              <p>
                Review the app releases and delete unnecessary releases to free
                up storage space.
                <br />
                The Storage Add-on allows for a specific storage limit. If your
                current usage exceeds this limit, remove unused app releases to
                stay within the allowed storage.
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'TEAM_MEMBERS',
      title: 'Manage Team Roles to Comply with Add-ons Limits',
      content: (
        <div>
          <div>
            <TeamRestriction />
          </div>
          <div className="text-area">
            <div>
              <h3>Check Team Members:</h3>
              <p>
                Verify the Team Members in the workspace with roles assigned as
                either ADMIN or DEVELOPER. If the number of such members exceeds
                the limit allowed by the Team Member Add-on, take the following
                steps:
              </p>
              <ul>
                <li>Remove the excess members from the workspace.</li>
                <li>
                  Alternatively, change their roles to TESTER to ensure
                  compliance with the allowed team member limits.
                </li>
              </ul>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const selectedContent = addonsContent.find(
    (addon) => addon.key === selectedAddOn?.subscriptionPlan?.name,
  );

  return (
    <Modal
      width={713}
      centered
      title={<h2>{selectedContent?.title}</h2>}
      closeIcon={false}
      open={visible}
      footer={false}
      destroyOnClose
      maskClosable={false}
      className="cancel-subscription-restriction-modal"
    >
      <div className="steps-content">
        {selectedContent?.content}
        <div className="next-button">
          <Button
            variant="outlined"
            type="primary"
            onClick={() => handleVisible(false)}
          >
            I, Understood
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelAddonRestrictionModal;
