import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { ROUTES } from '../../common/constants';
import Teams from './Teams';

const TeamWrapper = () => (
  <Switch>
    <Route path={ROUTES?.TEAMS} component={Teams} />
    <Route path="*" component={Error404} />
  </Switch>
);

export default TeamWrapper;
