import { useQuery } from '@apollo/client';
import { Button, Layout, Result } from 'antd';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Logo from '../../assets/images/logo-big.png';
import {
  AppStoreDownload,
  MobileAppStoreDownload,
  MobilePlayStoreDownload,
  PlayStoreDownload,
} from '../../assets/svg';
import noLink from '../../assets/svg/no-link.svg';
import {
  APPSONAIR_MAIN_LINK,
  DOWNLOAD_APPSTORE_URL,
  DOWNLOAD_PLAYSTORE_URL,
} from '../../common/constants';
import AdsComponent from '../../components/AdsComponent';
import CommonFooter from '../../components/CommonFooter';
import LoaderComponent from '../../components/LoaderComponent';
import Detail from './Detail';
import VerifyLinkPassword from './VerifyLinkPassword';
import './detail.less';
import { GET_CHECK_APP_VISIBILITY } from './graphql/queries';

const { Header, Content } = Layout;

const PublicPage = () => {
  const appID = useParams();
  const { subLinkId } = appID;
  const [loader, setLoader] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const [linkExpired, setLinkExpired] = useState(false);
  const [errorType, setErrorType] = useState('');
  const [disableAds, setDisableAds] = useState(false);

  // eslint-disable-next-line no-undef
  const isMobileScreen = !(window?.innerWidth > 768);

  // eslint-disable-next-line no-undef
  const isAdsForMobile = !(window?.innerWidth > 1400);

  const getCheckAppInput = {
    subLinkId,
  };

  const { data } = useQuery(GET_CHECK_APP_VISIBILITY, {
    fetchPolicy: 'network-only',
    variables: {
      getCheckAppInput,
    },
    onCompleted(res) {
      setLoader(false);
      setShowHeader(res?.checkAppVisibility?.isPrivate);
      setDisableAds(Boolean(res?.checkAppVisibility?.disableAds));
    },
    onError(error) {
      if (error?.graphQLErrors?.[0]?.extensions?.code === 'LINK_EXPIRED') {
        setLinkExpired(true);
      } else if (
        error?.graphQLErrors?.[0]?.extensions?.code === 'LINK_NOT_FOUND'
      ) {
        setErrorType('LINK_NOT_FOUND');
      } else {
        setErrorType('SOMETHING_WENT_WRONG');
      }
      setLoader(false);
      setLinkExpired(true);
    },
  });

  return (
    <>
      {loader ? (
        <LoaderComponent />
      ) : (
        <Layout className="gx-app-layout">
          <Helmet>
            <title>AppsOnAir - Share Your App Link</title>
          </Helmet>
          {!showHeader && (
            <Header>
              <a href={APPSONAIR_MAIN_LINK} rel="noreferrer">
                <img alt="apps-on-air" src={Logo} className="logo" />
              </a>
              <div className="d-flex align-center">
                <a
                  href={DOWNLOAD_APPSTORE_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {isMobileScreen ? (
                    <MobileAppStoreDownload height={35} width={50} />
                  ) : (
                    <AppStoreDownload height={40} width={150} />
                  )}
                </a>
                <a
                  href={DOWNLOAD_PLAYSTORE_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {isMobileScreen ? (
                    <MobilePlayStoreDownload height={35} width={50} />
                  ) : (
                    <PlayStoreDownload height={40} width={150} />
                  )}
                </a>
              </div>
            </Header>
          )}
          <Content className="gx-layout-content">
            {isAdsForMobile && !disableAds && (
              <div className="google-ad-banner">
                <AdsComponent dataAdSlot="5599605877" width={500} height={90} />
              </div>
            )}
            <div className="gx-main-content-wrapper">
              <div className="apps-content public-page">
                <div className="main-content">
                  {errorType === 'LINK_NOT_FOUND' && (
                    <Result
                      status="404"
                      title="404"
                      subTitle="Sorry, the page you visited does not exist."
                    />
                  )}
                  {errorType === 'SOMETHING_WENT_WRONG' && (
                    <Result
                      status="500"
                      title="Sorry, something went wrong."
                      subTitle="Click on reload page button to try again."
                      extra={
                        <Button
                          type="primary"
                          onClick={() => {
                            // eslint-disable-next-line no-undef
                            window.location.reload();
                          }}
                        >
                          Reload Page
                        </Button>
                      }
                    />
                  )}
                  {data?.checkAppVisibility?.isPrivate ? (
                    <VerifyLinkPassword
                      setShowHeader={setShowHeader}
                      appDetails={data?.checkAppVisibility?.applicationDetails}
                    />
                  ) : (
                    <>{!errorType?.length && <Detail />}</>
                  )}
                  {linkExpired && (
                    <div className="empty-no-link-image d-flex justify-center">
                      <div className="d-flex justify-center">
                        <img
                          src={noLink}
                          alt="no-link"
                          className="empty-image"
                        />
                      </div>
                    </div>
                  )}
                  {!isAdsForMobile && !disableAds && (
                    <div className="left-google-ad">
                      <AdsComponent dataAdSlot="3115003212" />
                    </div>
                  )}
                  {!isAdsForMobile && !disableAds && (
                    <div className="right-google-ad">
                      <AdsComponent dataAdSlot="5549594866" />
                    </div>
                  )}
                  {isAdsForMobile && !disableAds && (
                    <AdsComponent dataAdSlot="4947815866" />
                  )}
                </div>
              </div>
            </div>
            <CommonFooter />
          </Content>
        </Layout>
      )}
    </>
  );
};

export default PublicPage;
