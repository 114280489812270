import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../common/constants';

function UnauthorizedPage() {
  const history = useHistory();

  const handleClick = () => {
    history.replace(ROUTES?.APPS); // Navigates to /apps
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  return (
    <div className="status-page-wrapper">
      <Result
        status="403"
        title={
          <>
            <h1 className="font-semibold error-code">403</h1>
            <p className="access-forbiden">Access Forbidden</p>
          </>
        }
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button
            type="primary"
            className="go-back-btn"
            icon={<ArrowLeftOutlined />}
            onClick={handleClick}
          >
            Back to Home
          </Button>
        }
      />
    </div>
  );
}

export default UnauthorizedPage;
