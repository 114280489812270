import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Row } from 'antd';
import { React, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { REGEX, ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import './Login.less';
import { UPDATE_USER } from './graphql/mutations';
import AuthLayout from './layout/Layout';

const NewUser = () => {
  const {
    getCurrentUser,
    state: { userTeams },
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (getCurrentUser()?.firstName) {
      history?.replace(ROUTES?.APPS);
      setLoading(false);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [userMutate] = useMutation(UPDATE_USER, {
    onError() {},
  });

  const handleLogin = async (value) => {
    const { firstName, lastName } = value;
    try {
      await userMutate({
        variables: {
          data: {
            firstName,
            lastName,
          },
        },
      });
      if (userTeams?.length > 0) {
        history?.push(ROUTES?.APPS);
      } else {
        history?.push(ROUTES?.ONBOARD);
      }
    } catch (error) {
      return error;
    }
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <AuthLayout>
      <div className="gx-login-content">
        <div className="login-text">
          <span className="login-heading-text font-24">Enter your name</span>
        </div>
        <div className="create-user-form">
          <Form onFinish={handleLogin}>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  className="user-form"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Enter a valid First name',
                    },
                    {
                      pattern: REGEX?.NAME,
                      message: 'Enter a valid First name',
                    },
                  ]}
                >
                  <Input placeholder="First name" className="full-name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="user-form"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Enter a valid Last name',
                    },
                    {
                      pattern: REGEX?.NAME,
                      message: 'Enter a valid Last name',
                    },
                  ]}
                >
                  <Input placeholder="Last name" className="full-name" />
                </Form.Item>
              </Col>
            </Row>
            <Button
              className="done-btn font-500"
              type="primary"
              htmlType="submit"
            >
              Done
            </Button>
          </Form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default NewUser;
