import { Modal } from 'antd';
import React from 'react';

const AppServiceProFeatureModal = ({
  modalWidth = 713,
  wrapClassName,
  title,
  visible,
  setVisible,
  content,
  footer,
  closable = true,
  maskClosable = true,
}) => {
  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <Modal
      width={modalWidth}
      wrapClassName={`app-service-pro-feature-modal ${wrapClassName}`}
      open={visible}
      title={title}
      onCancel={handleCancel}
      footer={footer}
      maskClosable={maskClosable}
      closable={closable}
      centered
    >
      {content}
    </Modal>
  );
};

export default AppServiceProFeatureModal;
