import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Checkbox } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import OTPInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { messageContext } from '../../app/components/AppContextHolder';
import {
  CURRENT_EMAIL,
  GA_EVENT,
  GA_LABEL,
  IS_NEW_USER,
  OTP_ATTEMPT,
  OTP_SIZE,
  REDIRECT_WAITING,
  ROUTES,
  TERMS_AND_CONDITION_LINK,
} from '../../common/constants';
import { googleAnalyticsEvents } from '../../common/utils';
import './Login.less';
import { LOGIN, VERIFY_USER } from './graphql/mutations';
import { GET_USER_TEAM } from './graphql/queries';
import AuthLayout from './layout/Layout';

const VerifyUser = () => {
  const {
    state: { userEmail, currentUser },
    initializeAuth,
    getToken,
    dispatch,
  } = useContext(AppContext);
  const loginButtonRef = useRef(null);

  const [otpState, setOtpState] = useState('');
  const [otpCounter, setOtpCounter] = useState(OTP_ATTEMPT);
  const [agree, setAgree] = useState(false);
  const [showAgree] = useState(
    // eslint-disable-next-line no-undef
    localStorage?.getItem(IS_NEW_USER),
  );
  const history = useHistory();

  const idToken = getToken();

  const [getUser] = useLazyQuery(GET_USER_TEAM, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.getUserTeams?.length > 0) {
        dispatch({
          type: 'SET_USER_TEAMS',
          data: res?.getUserTeams,
        });
      }
      if (
        currentUser?.firstName?.length > 0 ||
        currentUser?.lastName?.length > 0
      ) {
        if (res?.getUserTeams?.length > 0) {
          history?.push(ROUTES?.APPS);
        } else {
          history?.push(ROUTES?.ONBOARD);
        }
      } else {
        history?.push(ROUTES?.CREATE_USER);
      }
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(REDIRECT_WAITING);
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(IS_NEW_USER);
    },
    onError() {},
  });

  const [verifyUserMutate, { loading }] = useMutation(VERIFY_USER, {
    onError() {},
  });

  const [resendOtp] = useMutation(LOGIN, {
    onCompleted() {
      setOtpCounter(otpCounter - 1);
    },
  });

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  useEffect(() => {
    if (otpState?.length === OTP_SIZE) {
      loginButtonRef?.current?.focus();
    }
  }, [otpState]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const isEmail = localStorage?.getItem(CURRENT_EMAIL);
    if (idToken) {
      history?.push(ROUTES?.ONBOARD);
    } else if (!isEmail) {
      history?.push(ROUTES?.LOGIN);
    } else {
      // eslint-disable-next-line no-undef
      localStorage?.setItem(REDIRECT_WAITING, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (otp) => {
    setOtpState(otp);
  };

  const handleVerification = async () => {
    if (otpState?.length === 6) {
      try {
        const res = await verifyUserMutate({
          variables: {
            email: userEmail,
            otp: otpState,
          },
        });
        if (res?.data?.verifyUser) {
          await initializeAuth(
            res?.data?.verifyUser?.token,
            res?.data?.verifyUser?.user,
            res?.data?.verifyUser?.user?.refreshToken,
          );
          getUser({
            context: {
              headers: {
                token: res?.data?.verifyUser?.token,
              },
            },
          });
          googleAnalyticsEvents(GA_EVENT?.VERIFY_USER_WITH_OTP, {
            label: GA_LABEL?.VERIFY_USER_WITH_OTP,
            member_id: res?.data?.verifyUser?.user?.id,
            user_email: res?.data?.verifyUser?.user?.email,
            otp: otpState,
          });
        }
      } catch (error) {
        return error;
      }
    } else {
      messageContext?.error('Please enter correct OTP');
    }
  };

  const resendOtpHandler = () => {
    resendOtp({
      variables: {
        email: userEmail,
      },
    });
    googleAnalyticsEvents(GA_EVENT?.SEND_OTP_AGAIN, {
      label: GA_LABEL?.SEND_OTP_AGAIN,
      user_email: userEmail,
      otp: otpState,
    });
  };
  return (
    <>
      <AuthLayout>
        <div className="gx-login-content">
          <div className="login-text">
            <span className="check-mail">Check Your Email</span>
            <span className="login-description">
              Enter OTP sent to <span className="font-500">{userEmail}</span>
            </span>
          </div>

          <div className="otp-div">
            <OTPInput
              value={otpState}
              onChange={handleChange}
              numInputs={OTP_SIZE}
              isInputNum
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus
              focusStyle="otp-input-focus"
              className="otp-input"
            />
            {showAgree && (
              <Checkbox className="otp-check" onChange={checkboxHandler}>
                I agree to the
                <a
                  href={TERMS_AND_CONDITION_LINK}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    googleAnalyticsEvents(GA_EVENT?.TERMS_AND_CONDITION_LINK, {
                      label: GA_LABEL?.TERMS_AND_CONDITION_LINK,
                      user_email: userEmail,
                    });
                  }}
                >
                  &nbsp;terms and conditions
                </a>
              </Checkbox>
            )}

            <div className={`otp-btn-div ${!showAgree ? 'mt-8' : ''}`}>
              <Button
                type="primary"
                className="otp-btn font-500"
                onClick={handleVerification}
                loading={loading}
                disabled={showAgree ? !agree : false}
                ref={loginButtonRef}
              >
                Login
              </Button>
              <span className="footer-text">
                Didn't receive OTP ?
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                  <span onClick={resendOtpHandler} className="resend-otp">
                    Send Again
                  </span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default VerifyUser;
