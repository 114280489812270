import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import noAppsImg from '../../assets/images/no-apps-img.png';
import { ACTIONS, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './style/no-apps.less';

const NoApps = () => {
  const history = useHistory();

  const handleClick = () => {
    history?.push(ROUTES?.CREATE_APP);
  };
  return (
    <div className="no-apps-div">
      <div className="no-apps-img">
        <img src={noAppsImg} alt="no-apps" />
      </div>
      <div className="no-apps-content">
        <h1>No apps in your workspace</h1>
        <AccessControl allowedPermissions={ACTIONS?.CREATE_APP}>
          <div className="no-apps-release-content">
            <p>Create an app and add releases in it</p>
          </div>

          <div className="no-apps-btn">
            <Button
              type="primary"
              onClick={handleClick}
              className="create-app-btn"
            >
              Create App
            </Button>
          </div>
        </AccessControl>
      </div>
    </div>
  );
};

export default NoApps;
