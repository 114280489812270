import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import NoAccess from '../NoAccess';
import { checkPermissions } from '../common/utils';
import LoaderComponent from './LoaderComponent';

const AccessControl = ({
  allowedPermissions = '',
  children,
  showNoAccess,
  renderNoAccess = () => (showNoAccess ? <NoAccess /> : null),
}) => {
  const {
    state: { currentRole },
  } = useContext(AppContext);

  if (!currentRole?.[0]?.role) {
    return <LoaderComponent setHeight={700} />;
  }

  const permitted = checkPermissions(
    currentRole?.[0]?.role,
    allowedPermissions,
  );

  if (permitted) {
    return children || permitted;
  }

  return renderNoAccess();
};

export default AccessControl;
