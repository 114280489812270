import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation loginUser($email: String!) {
    loginUser(email: $email) {
      status
      message
      isNewUser
    }
  }
`;

export const VERIFY_USER = gql`
  mutation verifyUser($email: String!, $otp: String!) {
    verifyUser(email: $email, otp: $otp) {
      token
      user {
        id
        firstName
        lastName
        email
        defaultWorkspace
        refreshToken
        allowMultiWorkspaceCreation
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($data: UpdateData) {
    updateUser(data: $data) {
      id
      firstName
      lastName
      email
      profileImage
      defaultWorkspace
      message
      allowMultiWorkspaceCreation
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logoutUser {
    logoutUser {
      message
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($data: DeleteAccountData) {
    deleteAccount(data: $data) {
      message
    }
  }
`;
