import { Button, Input, Modal } from 'antd';
import { capitalize, isNaN } from 'lodash';
import React, { useEffect, useState } from 'react';
import { AddCircle, MinusCircle } from '../../../../assets/svg';
import {
  ADD_ON,
  MAX_ADD_ONS_QUANTITY,
  MODAL_WIDTH,
} from '../../../../common/constants';

const EditAddOnsQuantityModal = ({
  visible,
  setVisible,
  selectedAddOn,
  setSelectedAddOn,
  editAddOn,
}) => {
  const [update, setUpdate] = useState([]);

  const handleOk = () => {
    editAddOn({
      variables: {
        data: {
          quantity: update?.quantity,
        },
        where: {
          id: selectedAddOn?.id,
        },
      },
    });
    setVisible(false);
  };

  const handleCancel = () => {
    setSelectedAddOn([]);
    setVisible(false);
  };

  const onHandlePlus = () => {
    if (
      update?.quantity <= MAX_ADD_ONS_QUANTITY &&
      update?.quantity < update?.originalQuantity
    ) {
      setUpdate((prevState) => {
        const currentAmount = prevState?.amount || 1;
        const currentQuantity = prevState?.quantity || 1;
        const newQuantity = Math.max(currentQuantity + 1, 1);
        const totalAmount = Math.max(currentAmount * newQuantity);
        return {
          ...prevState,
          quantity: newQuantity,
          totalQuantity:
            selectedAddOn?.subscriptionPlan?.name === ADD_ON?.APP
              ? newQuantity * 5
              : `${newQuantity * 10} GB`,
          total: totalAmount,
        };
      });
    }
  };

  const onHandleMinus = () => {
    if (update?.quantity > 1) {
      setUpdate((prevState) => {
        const currentAmount = prevState?.amount || 1;
        const currentQuantity = prevState?.quantity || 1;
        const newQuantity = Math.max(currentQuantity - 1, 1);
        const totalAmount = Math.max(currentAmount * newQuantity);
        return {
          ...prevState,
          quantity: newQuantity,
          totalQuantity:
            selectedAddOn?.subscriptionPlan?.name === ADD_ON?.APP
              ? newQuantity * 5
              : `${newQuantity * 10} GB`,
          total: totalAmount,
        };
      });
    }
  };

  const handleQuantityChange = (e) => {
    const newValue = parseFloat(e?.target?.value);
    const originalQuantity = selectedAddOn?.quantity || 1;
    let newQuantity;

    if (
      e.target.value === '' ||
      (!isNaN(newValue) && newValue >= 0 && newValue <= originalQuantity)
    ) {
      newQuantity = isNaN(newValue) ? 0 : newValue;
    } else {
      newQuantity = originalQuantity;
    }

    setUpdate((prevState) => {
      const currentAmount = prevState?.amount || 1;
      const totalAmount = Math.max(currentAmount * newQuantity);
      return {
        ...prevState,
        quantity: newQuantity,
        totalQuantity:
          selectedAddOn?.subscriptionPlan?.name === ADD_ON?.APP
            ? newQuantity * 5
            : `${newQuantity * 10} GB`,
        total: totalAmount,
      };
    });
  };

  useEffect(() => {
    let storageValue = selectedAddOn?.metaData?.STORAGE;
    if (storageValue != null) {
      storageValue = storageValue?.replace(/(\d+)(\w+)/, '$1 $2');
    }
    setUpdate({
      amount: selectedAddOn?.subscriptionPlan?.amount,
      feature: selectedAddOn?.subscriptionPlan?.subscriptionFeatures,
      originalQuantity: selectedAddOn?.quantity,
      quantity: selectedAddOn?.quantity,
      totalQuantity:
        selectedAddOn?.subscriptionPlan?.name === ADD_ON?.APP
          ? selectedAddOn?.metaData?.APP
          : storageValue,
      total: selectedAddOn?.subscriptionAmount,
      loading: false,
    });
  }, [selectedAddOn]);

  return (
    <div>
      <Modal
        centered
        className="edit-add-ons"
        title={`Update ${capitalize(
          selectedAddOn?.subscriptionPlan?.name,
        )} Add-ons`}
        width={MODAL_WIDTH}
        onCancel={handleCancel}
        open={visible}
        onOk={handleOk}
        footer={[
          <div key="footer-delete-release">
            <Button
              disabled={
                update?.quantity === selectedAddOn?.quantity ||
                update?.quantity === 0
              }
              className="delete-btn"
              onClick={handleOk}
              type="primary"
            >
              Update
            </Button>
          </div>,
        ]}
      >
        <div className="modal-content">
          <table>
            <thead>
              <tr>
                <th className="text-left">Add-ons Name</th>
                <th className="text-left">Price</th>
                <th className="text-center">Quantity</th>
                <th className="text-left">
                  {selectedAddOn?.subscriptionPlan?.name === ADD_ON?.APP
                    ? 'Total Apps'
                    : 'Total Storage'}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-left">
                  {selectedAddOn?.subscriptionPlan?.label}
                </td>
                <td className="text-left">
                  ${selectedAddOn?.subscriptionPlan?.amount}
                </td>
                <td className="text-center">
                  <div className="quantity">
                    <div className="number">
                      <MinusCircle
                        className={
                          update?.quantity === 1 || update?.quantity === 0
                            ? 'disable-button'
                            : ''
                        }
                        onClick={onHandleMinus}
                      />
                      <Input
                        className="quantity-input"
                        defaultValue={1}
                        value={update?.quantity}
                        onChange={(e) => handleQuantityChange(e)}
                      />
                      <AddCircle
                        className={
                          update?.quantity === MAX_ADD_ONS_QUANTITY ||
                          update?.quantity === update?.originalQuantity
                            ? 'disable-button'
                            : ''
                        }
                        onClick={onHandlePlus}
                      />
                    </div>
                  </div>
                </td>
                <td className="text-left">
                  <p className="feature-quantity m-0">
                    {update?.totalQuantity}
                  </p>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4}>
                  <div className="total-bill">
                    <h4 className="total">Total bill</h4>
                    <div className="total-amount">
                      <h4 className="price">$ {update?.total}</h4>
                      <p className="month">/ month</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default EditAddOnsQuantityModal;
