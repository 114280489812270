import { gql } from '@apollo/client';

export const ADD_ONS_TRANSACTION_HISTORY = gql`
  query TransactionHistory(
    $where: TeamUniqueInput!
    $filters: TransactionHistoryFilterInput
  ) {
    addonsTransactionHistory(where: $where, filters: $filters) {
      transactionHistory {
        id
        teamId
        teamSubscriptionId
        transactionId
        statusHistory
        subscriptionStartDate
        subscriptionEndDate
        amount
        quantity
        subscriptionPlan {
          id
          name
          type
          mode
        }
        teamSubscription {
          id
          subscriptionStatus
          subscriptionAmount
        }
        referenceData
      }
      count
    }
  }
`;

export const GET_INVOICE_LINK = gql`
  query GetCustomerInvoiceLink($where: TransactionInput!) {
    getCustomerInvoiceLink(where: $where) {
      url
    }
  }
`;
