import { useQuery } from '@apollo/client';
import { Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { PLANS } from '../common/constants';
import LoaderComponent from '../components/LoaderComponent';
import { GET_USER } from '../modules/users/graphql/queries';
import './App.less';
import ContentRoutes from './ContentRoutes';
import BannerLegacyUser from './components/BannerLegacyUser';
import AppHeader from './components/header/AppHeader';

const { Content } = Layout;

const App = () => {
  const {
    dispatch,
    state: { loading, teamPlan },
  } = useContext(AppContext);
  const legacyShow = process.env.REACT_APP_LEGACY_BANNER_SHOW === 'true';
  const isLegacyUser = teamPlan?.subscriptionPlan?.name === PLANS?.LEGACY;
  const [bannerDisplay, setBannerDisplay] = useState(false);

  useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      dispatch({
        type: 'SET_CURRENT_USER',
        data: res?.user,
      });
      dispatch({
        type: 'SET_LOADING',
        data: false,
      });
    },
    onError() {
      dispatch({
        type: 'SET_LOADING',
        data: false,
      });
    },
  });

  useEffect(() => {
    setBannerDisplay(isLegacyUser && legacyShow);
  }, [isLegacyUser, legacyShow]);

  // #AOA-1712 Commented as data is not added yet discussed in meeting
  // useEffect(() => {
  //   Gleap.identify(currentUser?.id, {
  //     name: currentUser?.name,
  //     email: currentUser?.email,
  //   });
  // }, [currentUser]);

  // #AOA-1712 Commented as data is not added yet discussed in meeting
  // useEffect(() => {
  //   Gleap.identify(currentUser?.id, {
  //     name: currentUser?.name,
  //     email: currentUser?.email,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (loading) return <LoaderComponent />;
  return (
    <Layout className="gx-app-layout">
      <AppHeader />
      {bannerDisplay && <BannerLegacyUser handleVisible={setBannerDisplay} />}
      <Content className="gx-layout-content">
        <div className="gx-main-content-wrapper">
          <ContentRoutes />
        </div>
      </Content>
    </Layout>
  );
};

export default App;
