import { Button, Modal } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Failed, Success } from '../assets/svg';

const PaymentModal = ({
  visible,
  setVisible,
  paymentModalStatus,
  setCountdownModalOpen,
}) => {
  const location = useLocation();

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handlePaymentFeedback = () => {
    setVisible(false);
    setCountdownModalOpen(true);
  };

  return (
    <Modal
      modalWidth={500}
      open={visible}
      onCancel={handleCloseModal}
      closable={paymentModalStatus !== 'success'}
      maskClosable={paymentModalStatus !== 'success'}
      footer={null}
      centered
    >
      <div className="d-flex flex-vertical align-center">
        <div className="payment-modal-content d-flex flex-vertical align-center text-center gap-24">
          {paymentModalStatus === 'success' ? <Success /> : <Failed />}
          <div>
            <h2>
              {paymentModalStatus === 'success'
                ? `${
                    location?.pathname?.includes('plan-payment')
                      ? 'Plan'
                      : 'Add-ons'
                  } purchased successfully!`
                : 'Payment Failed!'}
            </h2>
            <p className="m-0">
              {paymentModalStatus === 'success'
                ? 'Your payment was successful! You will receive an invoice on your registered email.'
                : 'Please try again. If it still doesn’t work. Check after a while. There might have been some network issue.'}
            </p>
          </div>
          {paymentModalStatus === 'success' && (
            <Button
              className="font-500"
              type="primary"
              htmlType="submit"
              onClick={handlePaymentFeedback}
            >
              Continue
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModal;
