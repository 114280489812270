import { Button, Carousel, Col, Row, Skeleton } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { messageContext } from '../../app/components/AppContextHolder';
import { PlanCoverApiKey, UpgradeCrown } from '../../assets/svg';
import documentCopy from '../../assets/svg/document-copy.svg';
import { DEVELOPER_GUIDELINES_URL, ROUTES } from '../../common/constants';
import { copyToClipboard } from '../../common/utils';
import AppNameHeader from '../../components/AppNameHeader';
import AppServices from './AppServices';
import './api-key.less';
import AppServiceProFeatureModal from './components/AppServiceProFeatureModal';

const ApiKey = () => {
  const {
    state: { teamId, isFreePlan },
  } = useContext(AppContext);
  const [proFeatureModalOpen, setProFeatureModalOpen] = useState(false);
  const history = useHistory();
  const appID = useParams();
  const id = appID?.appId;

  const copy = () => {
    copyToClipboard(id);
    messageContext?.success('API key copied to clipboard');
  };

  useEffect(() => {
    if (isFreePlan) {
      setProFeatureModalOpen(true);
    }
  }, []);

  return (
    <>
      <AppNameHeader />
      <AppServices>
        {isFreePlan && (
          <div className="app-service-pro-banner">
            <div className="description-section">
              <h2>Get the API Key feature</h2>
              <p>Upgrade to enable updates to your apps.</p>
            </div>
            <div className="button-section">
              <Button
                className="mr-16"
                onClick={() => {
                  setProFeatureModalOpen(true);
                }}
              >
                See what you get
              </Button>
              <Button
                type="primary"
                className="upgrade-button"
                onClick={() => {
                  history?.push(
                    `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                  );
                }}
              >
                <UpgradeCrown />
                Upgrade to pro
              </Button>
            </div>
          </div>
        )}
        <Row className={isFreePlan ? 'fade-feature' : ''}>
          <Col span={18}>
            <Row>
              <div className="s-content font-12 mb-16">
                To use this feature, you have to integrate our app SDK.
                View&nbsp;
                <u>
                  <a
                    href={DEVELOPER_GUIDELINES_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="link font-500"
                  >
                    Developer guidelines
                  </a>
                </u>{' '}
                to know more.
              </div>
            </Row>
            <div className="api-key-content">
              <Row className="row-content font-16">
                <span>App ID</span>
              </Row>
              <div className="api-key-copy">
                <p className="col-content">
                  {!isFreePlan ? (
                    <div>{id}</div>
                  ) : (
                    <Skeleton
                      className="api-key-skeleton"
                      title={false}
                      paragraph={{
                        rows: 1,
                      }}
                    />
                  )}
                </p>
                {!isFreePlan && (
                  <p className="col-content">
                    <img
                      src={documentCopy}
                      alt="icon"
                      onClick={() => copy(id)}
                    />
                  </p>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {proFeatureModalOpen && (
          <AppServiceProFeatureModal
            title="API key"
            visible={proFeatureModalOpen}
            setVisible={setProFeatureModalOpen}
            footer={
              <div className="text-center">
                <Button
                  type="primary"
                  className="upgrade-button"
                  onClick={() => {
                    history?.push(
                      `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                    );
                  }}
                >
                  <UpgradeCrown />
                  Upgrade to pro
                </Button>
              </div>
            }
            content={
              <Carousel autoplay draggable>
                <div className="app-service-carousel">
                  <PlanCoverApiKey />
                  <div className="text">
                    <p>Copy and share your API key.</p>
                  </div>
                </div>
              </Carousel>
            }
          />
        )}
      </AppServices>
    </>
  );
};

export default ApiKey;
