import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { GA_EVENT, GA_LABEL } from '../../common/constants';
import { googleAnalyticsEvents } from '../../common/utils';

const BannerLegacyUser = ({ handleVisible }) => {
  const {
    state: { currentUser, teamId, teamPlan },
  } = useContext(AppContext);
  return (
    <div className="legacy-banner">
      <div className="container">
        🎉 <b>Legacy User Exclusive:</b> Enjoy a <b>30%</b> Lifetime Discount on
        all plans!
        <Button
          size="small"
          type="primary"
          href="https://go.logicwind.com/early_access_discount_website"
          target="_blank"
          className="ml-8"
          onClick={() => {
            googleAnalyticsEvents(GA_EVENT?.LEGACY_CLAIM_NOW, {
              label: GA_LABEL?.LEGACY_CLAIM_NOW,
              member_id: currentUser?.id,
              workspace_id: teamId,
              current_plan_name: teamPlan?.subscriptionPlan?.name,
              current_plan_type: teamPlan?.subscriptionPlan?.type,
            });
          }}
        >
          Claim Now
        </Button>
        <div className="close-icon" onClick={() => handleVisible(false)}>
          <CloseOutlined />
        </div>
      </div>
    </div>
  );
};

export default BannerLegacyUser;
