import { Button, Result } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from './common/constants';

const NoAccess = () => (
  <div className="gx-page-error-container">
    <div className="gx-page-error-content">
      <Result
        status="403"
        title="403"
        subTitle="Sorry, You are not authorized to access this page."
        extra={
          <Link className="gx-btn gx-btn-primary" to={ROUTES?.APPS}>
            <Button type="primary">Home</Button>
          </Link>
        }
      />
    </div>
  </div>
);

export default NoAccess;
